@import "../src/assets/css/bootstrap.min.css";
@import "../src/assets/fonts/font-awesome/css/font-awesome.min.css";
@import "../src/assets/fonts/fonts.css";
@import "../src/assets/css/toastr.min.css";
@import "../src/assets/css/bootstrap-tagsinput.min.css";
@import "../src/assets/css/styles.css";
@import "../src/assets/css/notifications.css";
@import "../src/assets/css/integration_styles.css";
@import "../src/assets/css/login_styles.css";
/* @import "~video-react/dist/video-react.css"; */
@import "../src/assets/css/support_styles.css";
/*Integrations Section 1*/
#int-sec-1 {
  padding: 176px 0 72px;
  background: #1A1C2D;
  background: linear-gradient(to right, #1A1C2D 0%, #050606 100%);
  background: -moz-linear-gradient(left, #1A1C2D 0%, #050606 100%);
  background: -webkit-linear-gradient(left, #1A1C2D 0%, #050606 100%);
  background: linear-gradient(left, #1A1C2D 0%, #050606 100%);


}

#int-sec-1 .title-area h1 {
  font-size: 48px;
  font-weight: 600;
  line-height: 1;
  letter-spacing: -1px;
  text-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
  top: 1.1px;
}

#int-sec-1 .acc-wrapper {
  width: 100%;
  max-width: 120px;
  padding: 18.49px 10px;
  margin: 0 12px;
  border-radius: 8px;
  -webkit-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.4),
    0 0 20px 0 rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.4), 0 0 20px 0 rgba(0, 0, 0, 0.4);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.4), 0 0 20px 0 rgba(0, 0, 0, 0.4);
}

#int-sec-1 .acc-wrapper .img-wrapper {
  min-height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#int-sec-1 .acc-wrapper .label {
  font-weight: 600;
  color: #000000 !important;
}

#int-sec-1 .acc-wrapper .img-wrapper img:last-child,
#int-sec-1 .not-connected .img-wrapper img:first-child,
#int-sec-2 .acc-details-wrapper .img-wrapper img:last-child,
#int-sec-2 .not-connected .img-wrapper img:first-child {
  display: none;
}

#int-sec-1 .not-connected .img-wrapper img:last-child,
#int-sec-2 .not-connected .img-wrapper img:last-child {
  display: block;
}

/*End Integrations Section 1*/

/*Integrations Section 2*/
#int-sec-2 {
  padding: 40px 0 80px;
  background: #050606 ;
  background: linear-gradient(to right, #050606 0%,  #1A1C2D  100%);
  background: -moz-linear-gradient(to right, #050606 0%,  #1A1C2D  100%);
  background: -webkit-linear-gradient(to right, #050606 0%,  #1A1C2D  100%);
  background: linear-gradient(to right, #050606 0%,  #1A1C2D  100%);
}

#int-sec-2 .acc-details-wrapper {
  border-radius: 8px;
  box-shadow: 0 2px 10px 0 #000000;
}

#int-sec-2 .not-connected {
  align-items: center;
}

#int-sec-2 .acc-details-wrapper .img-wrapper {
  width: 100%;
  max-width: 64px;
}

#int-sec-2 .acc-details-wrapper .inner-row {
  padding-bottom: 20px;
  border-bottom: 1px solid #ccc;
  margin-bottom: 20px;
}

#int-sec-2 .acc-details-wrapper.not-connected .inner-row {
  padding: 0;
  margin: 0;
  border: 0;
}

#int-sec-2 .not-connected .acc-details h6 {
  border-bottom: solid 1px #ccc;
}

#int-sec-2 .acc-details h6 span {
  font-weight: 600;
  color: #000;
}

#int-sec-2 .acc-details-wrapper .btns-wrapper .btn {
  min-width: 140px;
}

#int-sec-2 .acc-details-wrapper .conn-btn-wrapper .btn {
  max-width: 220px;
  padding: 14px 0;
}



/*End Integrations Section 2*/

/*>>> Responsive <<<*/

/* Screen-size 991 */
@media screen and (max-width: 991px) {
  #int-sec-1 {
    padding: 150px 0 40px;
  }

  #int-sec-1 .acc-list {
    max-width: 470px !important;
  }

  #int-sec-1 .acc-wrapper {
    margin: 0 auto 25px !important;
    flex-basis: 33.33%;
  }

  #int-sec-2 .acc-details-wrapper .btns-wrapper {
    flex-basis: 100%;
    margin-top: 25px;
    justify-content: flex-end;
  }

  #app-footer {
    height: 7rem;
  }
}

/* Screen-size 767 */
@media screen and (max-width: 767px) {
  #int-sec-1 {
    padding: 140px 0 20px;
  }

  #int-sec-1 .title-area img {
    max-width: 260px;
  }

  #int-sec-1 .title-area h1 {
    letter-spacing: 0;
    padding: 15px 0 0 !important;
  }

  #int-sec-2 {
    padding: 40px 0 60px;
  }

  #int-sec-2 .acc-details-wrapper .btns-wrapper {
    flex-basis: 100%;
    margin-top: 25px;
    justify-content: center;
    flex-wrap: wrap;
  }

  #int-sec-2 .acc-details-wrapper .img-wrapper {
    margin: 0 auto 20px;
    flex-basis: 100%;
  }

  #int-sec-2 .acc-details-wrapper .acc-details {
    flex-basis: 100%;
  }
  #app-footer {
    height: 7rem;
  }
}

/* Screen-size 440 */
@media screen and (max-width: 440px) {
  .sm-txt {
    font-size: 12px;
    line-height: 1.5;
  }

  #int-sec-1 .title-area img {
    max-width: 220px;
  }

  #int-sec-1 .title-area h1 {
    font-size: 30px;
  }

  #int-sec-1 .acc-list {
    max-width: 280px !important;
  }

  #int-sec-1 .acc-wrapper {
    flex-basis: 50%;
  }

  #int-sec-2 .acc-details-wrapper {
    padding: 25px 15px !important;
  }

  #int-sec-2 .acc-details-wrapper .btns-wrapper .btn {
    margin: 0 auto 10px !important;
  }

  #app-footer {
    height: 7rem;
  }

  .login-justify {
    justify-content: center;
  }
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.searching-temp {
  color: #424344;
  cursor: pointer;
}

.searching-temp:hover {
  color: #ffffff;
}

.card-img-top {
  margin: auto;
  max-width: 84px;
}

.youtube:disabled {
  font: 700 16px/16px "Montserrat", sans-serif;
  width: 100%;
  max-width: 160px;
  padding: 14px;
  background-color: #ffffff;
  border-radius: 2px;
  box-shadow: 0 5px 10px 0 rgba(28, 183, 242, 0.5);
  border: solid 1.8px #1cb7f2;
  font-size: 16px;
  font-weight: 700;
  color: #1cb7f2;
}

.icon-wrapper p {
  font-size: 12px !important;
}

/* Editor part */
.video-part {
  position: relative;
  top: 13%;
}

#editor-tab-cont {
  overflow: hidden !important;
  padding: 0 !important;
  margin: 0 !important;
  height: 100%;
}

.video-editor {
  height: 60vh !important;
  width: 100% !important;
}

/* Loader */

@-webkit-keyframes rotation {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@-moz-keyframes rotation {
  0% {
    -moz-transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(360deg);
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.preloader {
  width: 80px;
  height: 80px;
  border: 2px #1cb7f2 solid;
  border-radius: 50%;
  -webkit-animation: rotation 1s ease-in-out infinite;
  -moz-animation: rotation 1s ease-in-out infinite;
  animation: rotation 1s ease-in-out infinite;
  text-align: center;
}
.preloader:after {
  width: 10px;
  height: 10px;
  background-color: #1cb7f2;
  border-radius: 100%;
  position: absolute;
  content: "";
}

/*================== Publish modal =======================*/

.publish-video-details:before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  width: 230px;
  height: 1px;
  background-color: #ccc;
}

.publish-video-details:after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  width: 230px;
  height: 1px;
  background-color: #ccc;
  left: unset;
  right: 0;
}
/* 
.publish-input {
  padding: 27px 16px 10px;
  color: #000;
  font: 400 14px/14px "SourceSansPro", sans-serif;
  color: #d19df8;
  max-width: 420px;
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(129, 51, 219, 0.55);
  border: 1px solid #8133db;
  outline: none;
} */

.publish-label,
#sel-acc ~ .label {
  color: #48cbff;
  font: 600 14px/14px "SourceSansPro", sans-serif;
  position: absolute;
  top: 10px;
  left: 16px;
  transform: translateY(0);
}

#sel-acc,
.publish-input {
  font-weight: 600;
  color: #000 !important;
  text-align: left;
  padding: 28px 16px 26px;
  z-index: 1;
  background-color: transparent;
  font: 400 14px/14px "SourceSansPro", sans-serif;
  color: #d19df8;
  max-width: 420px;
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(129, 51, 219, 0.55);
  border: 1px solid #1cb7f2;
  outline: none;
}

#sel-acc ~ .icon {
  position: absolute;
  top: 50%;
  right: 16px;
  color: #1cb7f2 !important;
  transform: translateY(-50%) rotateX(0);
  transition: all 0.3s ease-in-out;
}

#sel-acc ~ .dropdown-menu {
  box-shadow: 0 1px 2px 0 #e8c9ff;
  border: 1px solid #1cb7f2;
  background-color: hsla(0, 0%, 100%, 0.85);
  padding: 4px 16px 0;
}
#sel-acc ~ .dropdown-menu li {
  padding: 12px 0;
  border-bottom: 1px solid #e8c9ff;
}

#sel-acc ~ .label {
  top: 10px;
  transform: translateY(0);
}

/* Preview Editor */

.delete-preview-editor:hover {
  background-color: black !important;
}

.delete-preview-editor:hover .preview-delete-icon {
  color: white !important;
}

/* Support */

#support_item_wrapper img {
  width: 100% !important;
}

#support_item_wrapper p {
  color: white !important;
  font-size: 1rem !important;
}

.searching-data-hover:hover {
  border: 1px solid #1cb7f2;
}

.article-title-hover:hover {
  color: #4242a2 !important;
}

#support_item_wrapper p > span {
  color: white !important;
}

/* fbTempates, client Contract and Agency websites */

.fb-contract, .bg-app {
  /* min-height: 45rem; */
  min-height: calc(100vh - 61px);
  background: #1A1C2D;
  background: linear-gradient(to right, #1A1C2D 0%, #050606 100%);
  background: -moz-linear-gradient(left, #1A1C2D 0%, #050606 100%);
  background: -webkit-linear-gradient(left, #1A1C2D 0%, #050606 100%);
  background: linear-gradient(left, #1A1C2D 0%, #050606 100%);
}

.bg-app-1 {
  background:#050606;
  background: linear-gradient(to right,  #050606 0%, #1A1C2D 100%);
  background: -moz-linear-gradient(to right,  #050606 0%, #1A1C2D 100%);
  background: -webkit-linear-gradient(to right,  #050606 0%, #1A1C2D 100%);
  background: linear-gradient(to right,  #050606 0%, #1A1C2D 100%);

}

.fb-card .card-footer {
  background:#050606;
  background: linear-gradient(to right,  #050606 0%, #1A1C2D 100%);
  background: -moz-linear-gradient(to right,  #050606 0%, #1A1C2D 100%);
  background: -webkit-linear-gradient(to right,  #050606 0%, #1A1C2D 100%);
  background: linear-gradient(to right,  #050606 0%, #1A1C2D 100%);
  height: 5px !important;
}

/* nav for Bussiness nav */

.business-nav li > .nav-link {
  display: flex;
  justify-content: start !important;
}

.breadcrumb-item {
  width: 7rem;
}

.breadcrumb-item + .breadcrumb-item::before {
  content: none;
}

/* breadcrumb for whitelabel */

/* #breadcrumb {
  list-style: none;
  display: inline-block;
}

#breadcrumb .icon {
  font-size: 14px;
}

#breadcrumb li {
  float: left;
}

#breadcrumb li a {
  color: #fff;
  display: block;
  background: blue;
  text-decoration: none;
  position: relative;
  height: 40px;
  line-height: 40px;
  padding: 0 10px 0 5px;
  text-align: center;
  margin-right: 23px;
}

#breadcrumb li:nth-child(even) > a {
  background-color: blueviolet;
}

#breadcrumb li:nth-child(even) > a:before {
 */

#breadcrumb {
  list-style: none;
  display: block;
}
#breadcrumb .icon {
  font-size: 14px;
  color: white;
}
#breadcrumb li {
  float: left;
}
#breadcrumb li a {
  color: #fff;
  display: block;
  background: #1cb7f2;
  text-decoration: none;
  position: relative;
  width: 16.8vw;
  height: 40px;
  line-height: 40px;
  padding: 0 10px 0 5px;
  text-align: center;
  margin-right: 23px;
}
#breadcrumb li:nth-child(even) a {
  background-color: #1cb7f2;
}
/* #breadcrumb li:nth-child(even) a:before {
  border-color: #1cb7f2;
  border-left-color: transparent;
} */
/* #breadcrumb li:nth-child(even) a:after {
  border-left-color: #1cb7f2;
} */
/* #breadcrumb li:first-child a {
  padding-left: 15px;
  border-radius: 4px 0 0 4px;
} */
#breadcrumb li:first-child a:before {
  border: none;
}
/* #breadcrumb li:last-child a {
  padding-right: 15px;
  border-radius: 0 4px 4px 0;
} */
#breadcrumb li:last-child a:after {
  border: none;
}
#breadcrumb li a:before,
#breadcrumb li a:after {
  content: "";
  position: absolute;
  top: 0;
  border: 0 solid #1cb7f2;
  border-width: 20px 10px;
  width: 0;
  height: 0;
}
#breadcrumb li a:before {
  left: -20px;
  border-left-color: transparent;
}
#breadcrumb li a:after {
  left: 100%;
  border-color: transparent;
  border-left-color: #1cb7f2;
}
#breadcrumb li a:hover {
  background-color: #fff;
  color: #1cb7f2 !important;
}
#breadcrumb li a:hover:before {
  border-color: #fff;
  border-left-color: transparent;
  color: #1cb7f2;
}
#breadcrumb li a:hover:after {
  border-left-color: #fff;
  color: #1cb7f2;
}
#breadcrumb li a:active {
  background-color: #fff;
  color: #1cb7f2;
}
#breadcrumb li a:active:before {
  border-color: #fff;
  border-left-color: transparent;
  color: #1cb7f2;
}
#breadcrumb li a:active:after {
  border-left-color: #fff;
  color: #1cb7f2;
}

#breadcrumb li .selective {
  background-color: #fff !important;
  color: #1cb7f2 !important;
}

#breadcrumb li .selective:before {
  border-color: #fff;
  border-left-color: transparent;
  color: #1cb7f2;
}
#breadcrumb li .selective:after {
  border-left-color: #fff;
  color: #1cb7f2;
}


.icon-arrow{
  color:  #ff949f !important;
}




.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}


.box-1{
  border: 1px solid #fff;
  background-color: #181d21;
}

._3uApM {
  color: white !important;
}

.fs-45 {
  font-size: 40px;
}

.bg-c-form {
  background: rgb(24, 29, 33);
  padding-top: 20px;
  padding-bottom: 20px;
}


.create-podcast-section{
    padding: 129px 0 190px;
    background: #1A1C2D;
    background: linear-gradient(to right, #1A1C2D 0%, #050606 100%);
    background: linear-gradient(to right, #1A1C2D 0%, #050606 100%);
    overflow: hidden;
}


.bg-card {
 background-color: #181D21;
 min-height: 20vh;
 border: 3px solid #07080A;
}
   

.input-width input{
  width: 45px;
}


.space-evenly {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.centerd {
  display: flex;
    justify-content: center;
    align-items: center;
}


.centerd-top {
    display: flex;
    justify-content: center;
    align-items: flex-start;}

.min-h50{
  min-height: 50vh;
}



.input-dimension input {
  width: 71px;
}

.space-around {
  justify-content: space-around !important
}

.waveform-dimension{
  width:200px;
  height:60px
}

.font-in {
  font-size: 14px;
  width: 146px;
}


.img-res {
  height: 500px; 
  overflow-y: scroll;
  padding-top:50px;
  padding-bottom: 300px
}

.preview-tab-dim {
  width: 500px;
  height: 300px;
  margin-top: 50px;
  margin-bottom: 50px;
}