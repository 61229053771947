#proj-title-bar #search {
    border-radius: 4px;
    background-color: #171717;
    padding: 7.5px 13px;
    color:  #ECECEC;
    font: 600 14px/1.36 'SourceSansPro', sans-serif;
    height: auto;
    width: 100%;
    max-width: 453px;
    border: solid 1px #424344
}

.status-badge{
    width: 131px;
    height: 31px;
    border-radius: 4px;
    background-color: #1cb7f2;
}

.notification-text {
    text-align: left !important;
    padding-right: 5px !important;
}


.badges{
    width: 131px;
    display: flex;
    min-height: 31px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    color: #ffffff;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
}

.active-badges{
    background-color: #1cb7f2;
}

.success-badges {
   background-color:  #17d583;

}

.failed-badges{
    background-color: #f84440;
}

.hr-tr{
background-color: white;
padding-left: 33px;
padding-right: 33px;
}

.notification-status{
    float: right;
    padding-right: 33px;
}

.hr-span{
    border-bottom: 3px solid white !important;
}

.table-p {
    padding-right: 31px;
    padding-left: 33px;

}

.table-notification td, .table-notification th{
    padding: 24px 0px;

}

.table-border-btm tr{
    border-bottom: solid 1px #363839;
} 

.table-border-btm :last-child{
    border-bottom: solid 1px transparent;
} 
.time-info-text{
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #747474;
    display: block;

}


.input-search{
    min-height: 48px;
}

.mby-100{
    min-height: 145px;
}

.no-results {
   vertical-align: middle;
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
}
