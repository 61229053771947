@font-face {
	font-family: 'Montserrat';
	src: url(../fonts/Montserrat-ExtraLight.ttf);
	font-weight: 100;
}

@font-face {
	font-family: 'Montserrat';
	src: url(../fonts/Montserrat-Light.ttf);
	font-weight: 200;
}

@font-face {
	font-family: 'Montserrat';
	src: url(../fonts/Montserrat-Thin.ttf);
	font-weight: 300;
}

@font-face {
	font-family: 'Montserrat';
	src: url(../fonts/Montserrat-Regular.ttf);
	font-weight: 400;
}

@font-face {
	font-family: 'Montserrat';
	src: url(../fonts/Montserrat-Medium.ttf);
	font-weight: 500;
}

@font-face {
	font-family: 'Montserrat';
	src: url(../fonts/Montserrat-SemiBold.ttf);
	font-weight: 600;
}

@font-face {
	font-family: 'Montserrat';
	src: url(../fonts/Montserrat-Bold.ttf);
	font-weight: 700;
}

@font-face {
	font-family: 'Montserrat';
	src: url(../fonts/Montserrat-ExtraBold.ttf);
	font-weight: 800;
}

@font-face {
	font-family: 'Montserrat';
	src: url(../fonts/Montserrat-Black.ttf);
	font-weight: 900;
}

@font-face {
	font-family: 'SourceSansPro';
	src: url(../fonts/SourceSansPro-ExtraLight.ttf);
	font-weight: 200;
}

@font-face {
	font-family: 'SourceSansPro';
	src: url(../fonts/SourceSansPro-Light.ttf);
	font-weight: 300;
}

@font-face {
	font-family: 'SourceSansPro';
	src: url(../fonts/SourceSansPro-Regular.ttf);
	font-weight: 400;
}

@font-face {
	font-family: 'SourceSansPro';
	src: url(../fonts/SourceSansPro-SemiBold.ttf);
	font-weight: 600;
}

@font-face {
	font-family: 'SourceSansPro';
	src: url(../fonts/SourceSansPro-Bold.ttf);
	font-weight: 700;
}

@font-face {
	font-family: 'SourceSansPro';
	src: url(../fonts/SourceSansPro-Black.ttf);
	font-weight: 900;
}

@font-face {
	font-family: 'ProximaNova';
	src: url(../fonts/ProximaNova-Thin.otf);
	font-weight: 200;
}

@font-face {
	font-family: 'ProximaNova';
	src: url(../fonts/ProximaNova-Light.ttf);
	font-weight: 300;
}

@font-face {
	font-family: 'ProximaNova';
	src: url(../fonts/ProximaNova-Reg.otf);
	font-weight: 400;
}

@font-face {
	font-family: 'ProximaNova';
	src: url(../fonts/ProximaNova-Semibold.ttf);
	font-weight: 600;
}

@font-face {
	font-family: 'ProximaNova';
	src: url(../fonts/ProximaNova-Bold.otf);
	font-weight: 700;
}

@font-face {
	font-family: 'ProximaNova';
	src: url(../fonts/ProximaNova-Extrabold.otf);
	font-weight: 800;
}

@font-face {
	font-family: 'ProximaNova';
	src: url(../fonts/ProximaNova-Black.otf);
	font-weight: 900;
}