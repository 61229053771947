.pointMainDiv {
  position: relative;
  height: 20vh;
}

.pointChildDiv {
  position: absolute;
  top: 40%;
  left: 20%;
}

.pointChildDiv span:nth-child(1) {
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50px;
  background-color: #fff;
  top: 10px;
  left: 10px;
  display: inline-block;
}
.pointChildDiv span:nth-child(2) {
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50px;
  background-color: #fff;
  top: 7px;
  left: 12px;
  display: inline-block;
}
.pointChildDiv span:nth-child(3) {
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50px;
  background-color: #fff;
  top: 3px;
  left: 13px;
  display: inline-block;
}
.pointChildDiv span:nth-child(4) {
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50px;
  background-color: #fff;
  top: 0px;
  left: 15px;
  display: inline-block;
}
.pointChildDiv span:nth-child(5) {
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50px;
  background-color: #fff;
  top: -2px;
  left: 16px;
  display: inline-block;
}

.pointChildDiv span:nth-child(6) {
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50px;
  background-color: #fff;
  top: -5px;
  left: 17px;
  display: inline-block;
}

.pointChildDiv span:nth-child(7) {
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50px;
  background-color: #fff;
  top: -8px;
  left: 18px;
  display: inline-block;
}

.pointChildDiv span:nth-child(8) {
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50px;
  background-color: #fff;
  top: -11px;
  left: 19px;
  display: inline-block;
}

.pointChildDiv span:nth-child(9) {
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50px;
  background-color: #fff;
  top: -14px;
  left: 20px;
  display: inline-block;
}
.pointChildDiv span:nth-child(10) {
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50px;
  background-color: #fff;
  top: -19px;
  left: 21px;
  display: inline-block;
}
.pointChildDiv span:nth-child(11) {
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50px;
  background-color: #fff;
  top: -22px;
  left: 23px;
  display: inline-block;
}
.pointChildDiv span:nth-child(12) {
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50px;
  background-color: #fff;
  top: -25px;
  left: 25px;
  display: inline-block;
}
.pointChildDiv span:nth-child(13) {
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50px;
  background-color: #fff;
  top: -28px;
  left: 27px;
  display: inline-block;
}
.pointChildDiv span:nth-child(14) {
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50px;
  background-color: #fff;
  top: -31px;
  left: 29px;
  display: inline-block;
}
.pointChildDiv span:nth-child(15) {
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50px;
  background-color: #fff;
  top: -34px;
  left: 32px;
  display: inline-block;
}

.pointChildDiv span:nth-child(16) {
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50px;
  background-color: #fff;
  top: -36px;
  left: 35px;
  display: inline-block;
}

.pointChildDiv span:nth-child(17) {
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50px;
  background-color: #fff;
  top: -37px;
  left: 38px;
  display: inline-block;
}

.pointChildDiv span:nth-child(18) {
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50px;
  background-color: #fff;
  top: -38px;
  left: 41px;
  display: inline-block;
}
.pointChildDiv span:nth-child(19) {
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50px;
  background-color: #fff;
  top: -39px;
  left: 43px;
  display: inline-block;
}
.pointChildDiv span:nth-child(20) {
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50px;
  background-color: #fff;
  top: -40px;
  left: 46px;
  display: inline-block;
}
.pointChildDiv span:nth-child(21) {
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50px;
  background-color: #fff;
  top: -40px;
  left: 50px;
  display: inline-block;
}
.pointChildDiv span:nth-child(22) {
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50px;
  background-color: #fff;
  top: -40px;
  left: 52px;
  display: inline-block;
}
.pointChildDiv span:nth-child(23) {
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50px;
  background-color: #fff;
  top: -40px;
  left: 55px;
  display: inline-block;
}
.pointChildDiv span:nth-child(24) {
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50px;
  background-color: #fff;
  top: -40px;
  left: 58px;
  display: inline-block;
}
.pointChildDiv span:nth-child(25) {
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50px;
  background-color: #fff;
  top: -38px;
  left: 60px;
  display: inline-block;
}

.pointChildDiv span:nth-child(26) {
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50px;
  background-color: #fff;
  top: -35px;
  left: 62px;
  display: inline-block;
}
.pointChildDiv span:nth-child(27) {
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50px;
  background-color: #fff;
  top: -33px;
  left: 63px;
  display: inline-block;
}
.pointChildDiv span:nth-child(28) {
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50px;
  background-color: #fff;
  top: -30px;
  left: 64px;
  display: inline-block;
}
.pointChildDiv span:nth-child(29) {
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50px;
  background-color: #fff;
  top: -28px;
  left: 65px;
  display: inline-block;
}
.pointChildDiv span:nth-child(30) {
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50px;
  background-color: #fff;
  top: -26px;
  left: 66px;
  display: inline-block;
}
.pointChildDiv span:nth-child(31) {
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50px;
  background-color: #fff;
  top: -24px;
  left: 67px;
  display: inline-block;
}
.pointChildDiv span:nth-child(32) {
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50px;
  background-color: #fff;
  top: -22px;
  left: 68px;
  display: inline-block;
}
.pointChildDiv span:nth-child(33) {
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50px;
  background-color: #fff;
  top: -20px;
  left: 69px;
  display: inline-block;
}
.pointChildDiv span:nth-child(34) {
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50px;
  background-color: #fff;
  top: -17px;
  left: 70px;
  display: inline-block;
}
.pointChildDiv span:nth-child(35) {
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50px;
  background-color: #fff;
  top: -15px;
  left: 70px;
  display: inline-block;
}
.pointChildDiv span:nth-child(36) {
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50px;
  background-color: #fff;
  top: -13px;
  left: 70px;
  display: inline-block;
}
.pointChildDiv span:nth-child(37) {
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50px;
  background-color: #fff;
  top: -11px;
  left: 70px;
  display: inline-block;
}
.pointChildDiv span:nth-child(38) {
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50px;
  background-color: #fff;
  top: -9px;
  left: 70px;
  display: inline-block;
}
.pointChildDiv span:nth-child(39) {
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50px;
  background-color: #fff;
  top: -7px;
  left: 70px;
  display: inline-block;
}

.pointChildDiv span:nth-child(40) {
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50px;
  background-color: #fff;
  top: -4px;
  left: 70px;
  display: inline-block;
}
.pointChildDiv span:nth-child(41) {
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50px;
  background-color: #fff;
  top: -1px;
  left: 70px;
  display: inline-block;
}
.pointChildDiv span:nth-child(42) {
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50px;
  background-color: #fff;
  top: 3px;
  left: 70px;
  display: inline-block;
}
.pointChildDiv span:nth-child(43) {
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50px;
  background-color: #fff;
  top: 7px;
  left: 70px;
  display: inline-block;
}
.pointChildDiv span:nth-child(44) {
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50px;
  background-color: #fff;
  top: 10px;
  left: 70px;
  display: inline-block;
}
.pointChildDiv span:nth-child(45) {
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50px;
  background-color: #fff;
  top: 14px;
  left: 70px;
  display: inline-block;
}
