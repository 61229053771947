.lineMainDiv {
  height: 20vh;
  position: relative;
}

.lineChildDiv {
  position: absolute;
  top: 20%;
  left: 20%;
}

.lineChildDiv span:nth-child(1) {
  position: absolute;
  width: 5px;
  height: 70px;
  background-color: #fff;
  display: inline-block;
  top: 0px;
  left: 0px;
}
.lineChildDiv span:nth-child(2) {
  position: absolute;
  width: 5px;
  height: 60px;
  background-color: #fff;
  display: inline-block;
  top: 10px;
  left: 3px;
}
.lineChildDiv span:nth-child(3) {
  position: absolute;
  width: 5px;
  height: 60px;
  background-color: #fff;
  display: inline-block;
  top: 10px;
  left: 6px;
}
.lineChildDiv span:nth-child(4) {
  position: absolute;
  width: 5px;
  height: 50px;
  background-color: #fff;
  display: inline-block;
  top: 15px;
  left: 9px;
}
.lineChildDiv span:nth-child(5) {
  position: absolute;
  width: 5px;
  height: 50px;
  background-color: #fff;
  display: inline-block;
  top: 20px;
  left: 9px;
}

.lineChildDiv span:nth-child(6) {
  position: absolute;
  width: 5px;
  height: 40px;
  background-color: #fff;
  display: inline-block;
  top: 25px;
  left: 11px;
}
.lineChildDiv span:nth-child(7) {
  position: absolute;
  width: 5px;
  height: 40px;
  background-color: #fff;
  display: inline-block;
  top: 30px;
  left: 13px;
}
.lineChildDiv span:nth-child(8) {
  position: absolute;
  width: 5px;
  height: 30px;
  background-color: #fff;
  display: inline-block;
  top: 40px;
  left: 15px;
}
.lineChildDiv span:nth-child(9) {
  position: absolute;
  width: 5px;
  height: 20px;
  background-color: #fff;
  display: inline-block;
  top: 50px;
  left: 17px;
}
.lineChildDiv span:nth-child(10) {
  position: absolute;
  width: 5px;
  height: 10px;
  background-color: #fff;
  display: inline-block;
  top: 60px;
  left: 19px;
}
.lineChildDiv span:nth-child(11) {
  position: absolute;
  width: 5px;
  height: 20px;
  background-color: #fff;
  display: inline-block;
  top: 70px;
  left: 22px;
}
.lineChildDiv span:nth-child(12) {
  position: absolute;
  width: 5px;
  height: 30px;
  background-color: #fff;
  display: inline-block;
  top: 70px;
  left: 24px;
}
.lineChildDiv span:nth-child(13) {
  position: absolute;
  width: 5px;
  height: 40px;
  background-color: #fff;
  display: inline-block;
  top: 70px;
  left: 26px;
}
.lineChildDiv span:nth-child(14) {
  position: absolute;
  width: 5px;
  height: 30px;
  background-color: #fff;
  display: inline-block;
  top: 70px;
  left: 28px;
}
.lineChildDiv span:nth-child(15) {
  position: absolute;
  width: 5px;
  height: 30px;
  background-color: #fff;
  display: inline-block;
  top: 70px;
  left: 30px;
}

.lineChildDiv span:nth-child(16) {
  position: absolute;
  width: 5px;
  height: 30px;
  background-color: #fff;
  display: inline-block;
  top: 70px;
  left: 32px;
}
.lineChildDiv span:nth-child(17) {
  position: absolute;
  width: 5px;
  height: 60px;
  background-color: #fff;
  display: inline-block;
  top: 70px;
  left: 35px;
}
.lineChildDiv span:nth-child(18) {
  position: absolute;
  width: 5px;
  height: 60px;
  background-color: #fff;
  display: inline-block;
  top: 70px;
  left: 35px;
}
.lineChildDiv span:nth-child(19) {
  position: absolute;
  width: 5px;
  height: 60px;
  background-color: #fff;
  display: inline-block;
  top: 70px;
  left: 38px;
}
.lineChildDiv span:nth-child(20) {
  position: absolute;
  width: 5px;
  height: 70px;
  background-color: #fff;
  display: inline-block;
  top: 70px;
  left: 40px;
}

.lineChildDiv span:nth-child(21) {
  position: absolute;
  width: 5px;
  height: 70px;
  background-color: #fff;
  display: inline-block;
  top: 70px;
  left: 42px;
}
.lineChildDiv span:nth-child(22) {
  position: absolute;
  width: 5px;
  height: 60px;
  background-color: #fff;
  display: inline-block;
  top: 70px;
  left: 44px;
}
.lineChildDiv span:nth-child(23) {
  position: absolute;
  width: 5px;
  height: 50px;
  background-color: #fff;
  display: inline-block;
  top: 70px;
  left: 46px;
}
.lineChildDiv span:nth-child(24) {
  position: absolute;
  width: 5px;
  height: 40px;
  background-color: #fff;
  display: inline-block;
  top: 70px;
  left: 48px;
}
.lineChildDiv span:nth-child(25) {
  position: absolute;
  width: 5px;
  height: 30px;
  background-color: #fff;
  display: inline-block;
  top: 70px;
  left: 50px;
}
.lineChildDiv span:nth-child(26) {
  position: absolute;
  width: 5px;
  height: 20px;
  background-color: #fff;
  display: inline-block;
  top: 70px;
  left: 52px;
}
.lineChildDiv span:nth-child(27) {
  position: absolute;
  width: 5px;
  height: 10px;
  background-color: #fff;
  display: inline-block;
  top: 70px;
  left: 54px;
}
.lineChildDiv span:nth-child(28) {
  position: absolute;
  width: 5px;
  height: 70px;
  background-color: #fff;
  display: inline-block;
  top: 70px;
  left: 40px;
}
