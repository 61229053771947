.mainLine {
  position: relative;
  height: 20vh;
}
.childDiv {
  position: absolute;
  top: 20%;
  left: 20%;
}

.mainLine span:nth-child(1) {
  width: 5px;
  height: 100px;
  background-color: #fff;
  display: inline-block;
}
.mainLine span:nth-child(2) {
  width: 5px;
  height: 85px;
  position: absolute;
  top: 8px;
  left: 5px;
  background-color: #fff;
  display: inline-block;
}
.mainLine span:nth-child(3) {
  width: 5px;
  height: 75px;
  position: absolute;
  top: 10px;
  left: 8px;
  background-color: #fff;
  display: inline-block;
}

.mainLine span:nth-child(4) {
  width: 5px;
  height: 45px;
  position: absolute;
  top: 30px;
  left: 13px;
  background-color: #fff;
  display: inline-block;
}
.mainLine span:nth-child(5) {
  width: 5px;
  height: 10px;
  position: absolute;
  top: 46px;
  left: 18px;
  background-color: #fff;
  display: inline-block;
}

.childDivOne {
  position: absolute;
  top: 15%;
  left: 20%;
}

.childDivOne span:nth-child(1) {
  width: 5px;
  height: 50px;
  position: absolute;
  top: 42px;
  left: 23px;
  background-color: #fff;
  display: inline-block;
}

.childDivOne span:nth-child(2) {
  width: 5px;
  height: 50px;
  position: absolute;
  top: 42px;
  left: 25px;
  background-color: #fff;
  display: inline-block;
}

.childDivOne span:nth-child(3) {
  width: 5px;
  height: 50px;
  position: absolute;
  top: 42px;
  left: 28px;
  background-color: #fff;
  display: inline-block;
}
.childDivOne span:nth-child(4) {
  width: 3px;
  height: 60px;
  position: absolute;
  top: 37px;
  left: 33px;
  background-color: #fff;
  display: inline-block;
}

.childDivOne span:nth-child(5) {
  width: 5px;
  height: 50px;
  position: absolute;
  top: 42px;
  left: 36px;
  background-color: #fff;
  display: inline-block;
}

.childDivOne span:nth-child(6) {
  width: 5px;
  height: 50px;
  position: absolute;
  top: 42px;
  left: 38px;
  background-color: #fff;
  display: inline-block;
}

.childDivOne span:nth-child(7) {
  width: 5px;
  height: 80px;
  position: absolute;
  top: 27px;
  left: 40px;
  background-color: #fff;
  display: inline-block;
}

.childDivOne span:nth-child(8) {
  width: 5px;
  height: 80px;
  position: absolute;
  top: 27px;
  left: 42px;
  background-color: #fff;
  display: inline-block;
}
.childDivOne span:nth-child(9) {
  width: 5px;
  height: 80px;
  position: absolute;
  top: 27px;
  left: 42px;
  background-color: #fff;
  display: inline-block;
}
.childDivOne span:nth-child(10) {
  width: 5px;
  height: 80px;
  position: absolute;
  top: 27px;
  left: 45px;
  background-color: #fff;
  display: inline-block;
}
.childDivOne span:nth-child(11) {
  width: 5px;
  height: 95px;
  position: absolute;
  top: 18px;
  left: 47px;
  background-color: #fff;
  display: inline-block;
}

.childDivOne span:nth-child(12) {
  width: 5px;
  height: 67px;
  position: absolute;
  top: 34px;
  left: 51px;
  background-color: #fff;
  display: inline-block;
}

.childDivOne span:nth-child(13) {
  width: 5px;
  height: 50px;
  position: absolute;
  top: 42px;
  left: 56px;
  background-color: #fff;
  display: inline-block;
}

.childDivOne span:nth-child(14) {
  width: 5px;
  height: 10px;
  position: absolute;
  top: 61px;
  left: 60px;
  background-color: #fff;
  display: inline-block;
}
