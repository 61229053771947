html {
  overflow-x: hidden;
}

body {
  margin: 0;
  padding: 0 !important;
  background-color: #ffffff;
  overflow-x: hidden;
}

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  word-wrap: break-word;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  color: #555555;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", Sans-serif;
  font-weight: 700;
}

p,
a,
li,
span,
label {
  font: 400 14px/1.19 "Montserrat", Sans-serif;
  color: #9e9e9e;
}

p:last-child {
  margin-bottom: 0 !important;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

h1 {
  font-size: 40px;
  line-height: 50px;
}

h2 {
  font-size: 36px;
  line-height: 46px;
}

h3 {
  font-size: 32px;
  line-height: 1.22;
}

h4 {
  font-size: 24px;
  line-height: 32px;
}

h5 {
  font-size: 22px;
  line-height: 32px;
}

h6,
.sub-txt {
  font-size: 20px;
  line-height: 1.6;
}

a,
p,
input[type="submit"],
button,
span {
  text-decoration: none !important;
  -webkit-transition: all 0.5s ease !important;
  -moz-transition: all 0.5s ease !important;
  transition: all 0.5s ease !important;
}

a:hover,
a:hover p,
a:hover span,
a:hover i {
  color: #ef4949 !important;
}

input[type="search" i]::-webkit-search-cancel-button {
  -webkit-appearance: unset;
}

/* .container-fluid {
  max-width: 1920px;
} */
.main_pdf li a {
  color: #131521 !important;
}

.main_pdf li a:hover {
  color: #131521 !important;
}

.trainHead {
  margin: auto auto 20px;
  text-align: center;
  font-weight: 500;
  line-height: normal;
  color: #01b0f6;
  text-shadow: none;
}

.translate_btn {
  background-color: #ef4949 !important;
  color: #fff !important;
  float: right;
  margin-top: 5px;
  padding: 3px 5px;
  width: 120px;
  border: none;
  border-radius: 0.25rem;
  padding: 5px 0;
}

.translate_btn:hover {
  background-color: #fff !important;
  color: #ef4949 !important;
}

@media screen and (min-width: 1200px) {

  .tpc-container,
  .custom-wrapper,
  .billing-wrapper {
    width: 100%;
    max-width: 1190px;
    padding: 0 15px;
    margin: 0 auto;
  }

  .billing-wrapper {
    max-width: 1200px;
  }

  .tpc-container {
    max-width: 1188px;
  }
}

.big-heading {
  font-size: 72px;
  line-height: 1.0833;
  font-weight: 800;
}

.sub-heading {
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
  letter-spacing: 2px;
}

.md-txt {
  font-size: 18px;
}

.sm-txt {
  font-size: 14px;
}

.btn-1 {
  font: 700 16px/16px "Montserrat", sans-serif;
  width: 100%;
  max-width: 250px;
  padding: 16px;
  line-height: 1;
  border-radius: 35px;
  -webkit-box-shadow: 0 2px 10px 0 rgba(243, 11, 11, 0.8);
  box-shadow: 0 2px 10px 0 rgba(243, 11, 11, 0.8);
  background-size: 200% auto;
  background-color: #ef4949;
}

.btn-2 {
  font: 700 16px/16px "Montserrat", sans-serif;
  width: 100%;
  max-width: 160px;
  padding: 14px;
  background-color: #ffffff;
  border-radius: 2px;
  box-shadow: 0 5px 10px 0 rgba(255, 105, 105, 0.5);
  border: solid 1.8px #ff949f;
  font-size: 16px;
  font-weight: 700;
  color: #ef4949;
}

.btn-3 {
  font: 700 16px/16px "Montserrat", sans-serif;
  color: #ffffff;
  width: 100%;
  max-width: 210px;
  padding: 14px;
  border: solid 1.8px #fa6161;
  border-radius: 2px;
  background-color: #ef4949;
}

.btn-2:hover {
  background-color: #ef4949;
  color: #ffffff !important;
}

.gallary {
  width: 100%;
  overflow-y: auto;
  height: 15vh;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  display: flex;
}

.gallary img {
  width: 100%;
  display: inline-block;
  height: 100%;
  cursor: pointer;
}

.gallary-images {
  width: 135px;
  /* height: 100px; */
  display: inline-block;
  margin-bottom: 20px;
}

.gallary-images :first-child {
  right: 7px;
  z-index: 1;
  width: 13px;
  position: absolute;
  cursor: pointer;
  top: 10%;
}

.gallary-images:hover span {
  display: block !important;
}

.gallary-images span {
  font-size: 16px;
  color: #ea5e5f;
  display: none;
}

.btn-1:hover,
.btn-1:hover span {
  background-color: #ffffff;
  background-position: right center;
  color: #ef4949 !important;
}

.btn:focus {
  box-shadow: none;
}

.btn:disabled {
  color: #ffffff;
  cursor: pointer !important;
  background-color: #ef4949 !important;
  border-color: #ef4949 !important;
  opacity: 1;
  pointer-events: none;
  font-weight: 700;
  margin: 0;
  padding: 0;
}

.btn-3:hover {
  background-color: #ffffff;
  color: #ef4949 !important;
}

.user-img span {
  display: inline-block;
  width: 100px;
  height: 100px;
  background: #fff;
  border-radius: 100px;
  font-size: 50px;
  line-height: 100px;
  cursor: pointer;
}

.btn-1 i {
  font-size: 24px;
}

.tag {
  font-size: 10px;
  font-weight: 600;
  line-height: 1;
  color: #1cb7f2;
  letter-spacing: 1px;
  text-transform: uppercase;
  padding: 9px;
  border-radius: 2px;
  border: solid 1px #1cb7f2;
  background-color: #f5effd;
  text-align: center;
  margin-right: 8px;
  margin-bottom: 8px;
}

.bg-dark {
  background-color: #171717 !important;
}

.flex-elem {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
}

.input-dark-accnt,
.bootstrap-tagsinput {
  font: 400 16px/1.5 "SourceSansPro", sans-serif;
  color: #000 !important;
  padding: 22px 10px 14px;
  border-radius: 8px !important;
  border: solid 1px #ececec;
  background-color: #fff !important;
  resize: none;
  height: 56px;
  letter-spacing: 1px;
}

.intro-btn a {
  padding: 15px 50px;
  background-color: #f84440;
  color: #ffffff !important;
  border-radius: 30px;
}

.intro-btn a:hover {
  color: #f84440 !important;
  background-color: #fff !important;
}

.input-dark,
.bootstrap-tagsinput {
  font: 400 16px/1.5 "SourceSansPro", sans-serif;
  color: #ececec !important;
  padding: 22px 10px 14px;
  border-radius: 8px !important;
  border: solid 1px #000;
  background-color: #000 !important;
  resize: none;
  height: 56px;
  letter-spacing: 1px;
}

.input-dark:hover,
.input-dark:focus {
  -webkit-box-shadow: 0 0 20px 0 #000000;
  box-shadow: 0 0 20px 0 #000000;
  border-color: #9e9e9e;
}

.input-dark~label {
  position: absolute;
  top: 16px;
  left: 16px;
  z-index: 4;
}

.file-type {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
  cursor: pointer;
  opacity: 0;
}

.file-type::-webkit-file-upload-button {
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.v-center {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
}

body.modal-open {
  height: 100vh;
}

.modal-dialog {
  max-width: 644px;
  width: 100%;
}

.modal.fade .modal-dialog {
  -webkit-transform: scale(0.5);
  transform: scale(0.5);
}

.modal.show .modal-dialog {
  -webkit-transform: none;
  transform: none;
}

.modal.modal-static .modal-dialog {
  -webkit-transform: scale(1.02);
  transform: scale(1.02);
}

.modal-content {
  background-color: transparent;
  border: 0;
}

.modal-body {
  border-radius: 4px;
  border: solid 1px #c7cdd4;
  box-shadow: 0 4px 8px 0 rgba(83, 96, 107, 0.5);
}

body .modal-backdrop {
  opacity: 0.8 !important;
}

.modal-open section {
  -webkit-filter: blur(10px);
  filter: blur(10px);
}

.modal-open .modal {
  background-color: rgba(0, 0, 0, 0.3);
  overflow: hidden;
}

/*App Header*/
#app-header,
#app-editor-header {
  background-color: #060818;
  padding: 20px 0;
  top: 0;
  left: 0;
  z-index: 11;
  -webkit-box-shadow: 0 2px 20px 0 #060818;
  box-shadow: 0 2px 20px 0 #060818;
  max-height: 80px;
  /* display: flex;
    align-items: center; */
}

header::after {
  content: "";
  background: #08125a;
  background: -moz-linear-gradient(left,
      #05071d 0%,
      #05071d 50%,
      #060818 50%,
      #1a1c2d 100%);
  background: -webkit-linear-gradient(left,
      #05071d 0%,
      #05071d 50%,
      #060818 50%,
      #1a1c2d 100%);
  background: linear-gradient(to right,
      #05071d 0%,
      #05071d 50%,
      #060818 50%,
      #1a1c2d 100%);
  filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#0680db', endColorstr='#00e86a', GradientType=1);
  width: 100%;
  height: 9px;
  display: block;
}

#app-editor-header {
  padding: 17px 0;
}

#app-editor-header~#proj-title-bar {
  margin-top: 72px;
}

#app-header .navbar-nav li a {
  padding: 0 20px;
  font-weight: 400;
}

#app-header .navbar-nav li.active a {
  color: #1cb7f2 !important;
  font-weight: 800;
}

#app-header .dropdown-menu {
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  transform: translateX(-50%);
  margin-top: 13px;
  padding: 15px 0px;
  width: 185px;
  background-color: #060818;
}

#app-header .dropdown-menu .dropdown-item {
  padding: 8px 0;
  font-weight: 500;
  color: #000;
  font-size: 12px;
  padding-left: 10px;
}

/*End App Header*/

/*Dashboard Banner*/
#intro-section {
  padding: 260px 0 190px;
  background: #1a1c2d;
  background: linear-gradient(to right, #1a1c2d 0%, #050606 100%);
  background: -moz-linear-gradient(to right, #1a1c2d 0%, #050606 100%);
  background: -webkit-linear-gradient(to right, #1a1c2d 0%, #050606 100%);
  background: linear-gradient(to right, #1a1c2d 0%, #050606 100%);
  overflow: hidden;
}

#project-section {
  background: #050606;
  background: linear-gradient(to right, #050606 0%, #1a1c2d 100%);
  background: -moz-linear-gradient(to right, #050606 0%, #1a1c2d 100%);
  background: -webkit-linear-gradient(to right, #050606 0%, #1a1c2d 100%);
  background: linear-gradient(to right, #050606 0%, #1a1c2d 100%);
}

#intro-section #bg-video {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  bottom: 0;
  z-index: -1;
}

#bg-video iframe {
  position: absolute;
  min-height: 100vh;
  min-width: 177.77vh;
  width: 100vw;
  height: 56.25vw;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

#intro-section .title-area h1 {
  -webkit-text-stroke: 1px #ffffff;
  background-color: rgba(255, 255, 255, 0);
  color: rgba(255, 255, 255, 0);
}

#intro-section .action-btn {
  height: 104px;
  font-weight: 500;
  width: 100%;
  max-width: 455px;
  padding: 19px 32px;
  border: solid 1px #555555;
  background-color: #1a1c2d;
  cursor: pointer;
}

#intro-section .action-btn:hover span {
  color: #ff949f !important;
}

/*End Dashboard Banner*/

/* Dashboard Project*/
#project-section {
  padding: 48px 60px !important;
}

#project-section .view-all:hover,
#project-section .view-all:hover span {
  color: #fff !important;
}

#project-section .row-2 {
  margin: 10px -7px;
}

#project-section .status {
  width: 100%;
  max-width: 100%;
  min-height: 250px;
  cursor: pointer;
  overflow: hidden;
}

#project-section .status.awaiting-customization {
  background-image: -webkit-linear-gradient(141deg,
      #2c32b8 -9%,
      #7033d5 35%,
      #1cb7f2 53%,
      #9933e6 70%);
  background-image: linear-gradient(141deg,
      #2c32b8 -9%,
      #7033d5 35%,
      #1cb7f2 53%,
      #9933e6 70%);
}

#project-section .status.transcript-failed,
#project-section .status.rendering-failed {
  background: rgb(215, 83, 72);
  background: -moz-linear-gradient(top,
      rgba(215, 83, 72, 1) 0%,
      rgba(162, 0, 15, 1) 50%);
  background: -webkit-linear-gradient(top,
      rgba(215, 83, 72, 1) 0%,
      rgba(162, 0, 15, 1) 50%);
  background: linear-gradient(to bottom,
      rgba(215, 83, 72, 1) 0%,
      rgba(162, 0, 15, 1) 50%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#1cb7f2', endColorstr='#a2000f', GradientType=0);
}

#project-section .status.rendering,
#project-section .status.transcribing {
  background-color: #ffffff;
  flex-wrap: wrap;
  text-align: center;
}

#project-section .status.rendering .status-img,
#project-section .status.transcribing .status-img {
  position: relative;
  top: 0;
  opacity: 1;
  mix-blend-mode: unset;
  transform: unset;
  align-self: flex-end;
  margin-bottom: 8px;
}

#project-section .status.rendering p,
#project-section .status.transcribing p {
  flex-basis: 100%;
  font-weight: 500;
  color: #747474;
  align-self: flex-start;
}

/* #project-section .status.transcribing:after,
#project-section .status.transcribing .actions-icon*/
#project-section .status.rendering:after,
#project-section .status.rendering .actions-icon {
  display: none;
}

#project-section .status .status-img {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
  /* mix-blend-mode: overlay; */
  opacity: 0.9;
  width: 38px;
  height: 38px;
}

#project-section .status .proj_img {
  width: 100%;
  max-height: 250px;
  min-height: 250px;
  object-fit: cover;
}

#project-section .actions-icon {
  flex-wrap: nowrap;
  justify-content: center;
  position: absolute;
  top: 85%;
  left: 0;
  right: 0;
  opacity: 0;
  z-index: 2;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

#project-section .card-wrapper .status:hover .actions-icon,
#project-section .card-wrapper .img-wrapper:hover .actions-icon {
  top: 50%;
  opacity: 1;
}

#project-section .actions-icon .icon-wrapper {
  display: block;
  text-align: center;
  flex-basis: 20%;
  z-index: 10;
}

#project-section .actions-icon a {
  display: block;
  color: #ffffff;
  width: 48px;
  height: 48px;
  margin: 0 auto;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.65);
  line-height: 48px;
}

#project-section .card-wrapper .status:after,
#project-section .card-wrapper .img-wrapper:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.65);
  opacity: 0;
  z-index: 1;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

#project-section .card-wrapper .status:hover:after,
#project-section .card-wrapper .img-wrapper:hover:after {
  opacity: 1;
}

.dashboard-page .meta-wrapper {
  pointer-events: none;
}

/*End Dashboard Project*/

/*Dashboard Lesson*/
#lesson-section {
  background: #1a1c2d;
  background: linear-gradient(to right, #1a1c2d 0%, #050606 100%);
  background: -moz-linear-gradient(to right,
      #1a1c2d 0%,
      #050606 100%) !important;
  background: -webkit-linear-gradient(to right,
      #1a1c2d 0%,
      #050606 100%) !important;
  background: linear-gradient(to right, #1a1c2d 0%, #050606 100%) !important;
  padding: 69px 49px;
  background: url("../images/bg-ad.svg") 50% / cover no-repeat;

  background-color: currentcolor;
}

#lesson-section .btn-2:hover {
  background-color: #ef4949;
  color: #ffffff !important;
  box-shadow: 0 8px 16px 0 rgba(216, 16, 26, 0.6);
}

/*End Dashboard Lesson*/

/*Dashboard Feature*/
#feature-section {
  padding: 108px 0 130px;
}

#feature-section .row {
  margin-bottom: 154px;
}

#feature-section .row-1 img {
  transform: scale(1.022);
  transform-origin: left;
  margin-left: 200px;
}

#feature-section .row-2 img {
  transform: scale(1.199);
  transform-origin: left;
}

#feature-section .row-2 {
  margin-bottom: 177px;
}

#feature-section .row-3 img {
  transform: scale(1.132);
  transform-origin: left;
  margin-left: 33px;
}

#feature-section .txt-block {
  padding: 14px 14px 14px 24px;
  border-left: 2px solid #1cb7f2;
}

/*End Dashboard Feature*/

/*App Footer*/
#app-footer {
  background-color: #060818;
  height: 3.8rem;
  display: flex;
  align-items: center;
}

#footer-nav li a {
  padding: 0 10px;
  font-weight: 500;
  font-size: 12px;
}

#app-footer .social-icons a {
  font-size: 20px;
}

/*End App Footer*/

/*Watch Video Modal*/
#watch-video .modal-dialog {
  max-width: 1330px;
}

#watch-video .modal-content {
  max-width: 1130px;
}

.vid-wrapper {
  overflow: hidden;
}

.plyr__poster {
  background-size: cover;
}

.plyr__control.plyr__control--overlaid {
  background-color: transparent !important;
  opacity: 1;
}

.plyr__control--overlaid svg {
  height: 64px;
  width: 64px;
  fill: #9b33e9;
  left: 0;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.plyr__control--overlaid:hover svg {
  opacity: 0.8;
}

.plyr--video .plyr__control:hover {
  background-color: transparent;
}

.plyr--full-ui input[type="range"] {
  color: #fff;
}

.plyr__time {
  font: 500 14px/1 "Montserrat", sans-serif;
  color: #ffffff;
}

.plyr__volume {
  max-width: 40px;
  min-width: 0;
  margin-left: 12px !important;
}

.plyr video {
  object-fit: cover;
}

/*End Watch Video Modal*/

/*Progress Modal*/
#prog-modal .modal-body {
  padding: 40px;
}

#prog-modal .progress-label {
  font: 600 24px / normal "Montserrat", sans-serif;
  color: #1cb7f2;
}

#prog-modal .progress {
  border-radius: 12px;
  background-color: #e8e8e8;
}

#prog-modal .progress-bar {
  background-image: linear-gradient(94deg,
      #17d583 -11%,
      #00d2b3 39%,
      #5bc1de 59%,
      #1cb7f2 78%);
  background-image: linear-gradient(94deg,
      #17d583 -11%,
      #00d2b3 39%,
      #5bc1de 59%,
      #1cb7f2 78%);
  transition: none;
}

/*End Progress Modal*/

/*Upload Video/Audio Modal*/
#upload-vid-aud .modal-body {
  padding: 40px 56px 32px;
}

#upload-vid-aud #proj-name {
  padding: 18px 16px;
  font: 500 14px/14px "Montserrat", sans-serif;
  color: #000;
  caret-color: #9b33e9;
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 #e8c9ff;
  border: solid 1.8px #d19df8;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

#upload-vid-aud #proj-name::placeholder {
  color: #e8c9ff;
}

#upload-vid-aud #proj-name:hover::placeholder {
  color: #1cb7f2;
}

#upload-vid-aud p,
#upload-vid-aud p span {
  font-family: "Source Sans Pro", sans-serif;
  color: #262626;
}

#upload-vid-aud #proj-name:hover {
  border: solid 1.8px #9b33e9;
}

#upload-vid-aud #proj-name:focus {
  box-shadow: 0 2px 4px 0 rgba(155, 51, 233, 0.5);
  border: solid 1.8px #9b33e9;
}

#upload-vid-aud .save-project {
  padding: 14px;
}

/*End Upload Video/Audio Modal*/

/*Record Audio Modal*/
#rec-aud .modal-body {
  padding: 76px 50px 33px;
  background-image: linear-gradient(139deg,
      #2c32b8 -9%,
      #7033d5 34%,
      #1cb7f2 52%,
      #9933e6 69%);
}

#rec-aud #rec-title {
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.35);
  font-weight: 600;
  line-height: normal;
}

#rec-aud #time-display {
  font-size: 96px;
  font-weight: 100;
  letter-spacing: 1.5px;
}

#rec-aud #record {
  color: #1cb7f2;
  max-width: 72px;
  max-height: 72px;
  min-height: 72px;
  padding: 20.5px;
  border: 0;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.24);
  align-items: center;
  justify-content: center;
}

#rec-aud #record:hover {
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.55);
}

#rec-aud #record:active,
#rec-aud #record:focus {
  outline: 0;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.55);
  border: solid 1.8px #d19df8;
  background-color: #faf2ff !important;
}

#rec-aud .modal-footer {
  padding: 24px;
}

#rec-aud .modal-footer .btn {
  min-width: 200px;
  line-height: 1.5;
  padding: 11px;
  margin: 0;
}

#rec-aud .modal-footer .btn-2 {
  box-shadow: 0 2px 4px 0 rgba(155, 51, 233, 0.35);
  border: solid 1.8px #1cb7f2;
}

#rec-aud .modal-footer .btn:disabled {
  color: rgba(129, 51, 219, 0.35);
  opacity: 1;
  box-shadow: 0 2px 4px 0 rgba(232, 201, 255, 0.5);
  background-color: #ef4949 !important;
  border-color: #ef4949 !important;
  pointer-events: none;
  cursor: pointer !important;
}

#wave {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#wave .col-md-3 {
  height: 117px;
}

#wave img {
  width: 100%;
  height: 100%;
  position: absolute;
  transform: scale(0.9);
  transition: 0.3s ease-in-out;
  overflow: hidden;
  background-color: #000;
  cursor: pointer;
}

#wave img:hover {
  transition: 0.3s ease-in-out;
  transform: scale(1);
}

#wave wave {
  top: 50%;
  transform: translateY(-50%);
  opacity: 0.51;
}

/*End Record Audio Modal*/

/*Confirm Modal*/
#upload-vid-aud .modal-body,
#confirm-del .modal-body {
  padding: 57px 30px;
  border-radius: 4px;
  border: solid 1px #c7cdd4;
  box-shadow: 0 4px 8px 0 rgba(83, 96, 107, 0.5);
}

#confirm-del .modal-body p {
  line-height: normal;
  color: #262626;
  max-width: 345px;
  font-family: "SourceSansPro", sans-serif;
}

#confirm-del .modal-footer .btn {
  max-width: 200px;
  width: 100%;
  padding: 14px;
  border-radius: 2px;
  box-shadow: 0 2px 4px 0 rgba(239, 73, 73, 0.35);
  border: solid 2px #ef4949;
  margin: 3.5px;
}

#confirm-del .modal-footer .btn-1 {
  box-shadow: 0 2px 4px 0 rgba(239, 73, 73, 0.5);
  background-color: #1cb7f2;
}

/*End Confirm Modal*/

/*Publish Modal*/
#publish .modal-body {
  padding: 40px 24px;
}

#publish .modal-body .icons-wrapper .icon-wrapper {
  flex-basis: 15%;
}

#publish .modal-body .icons-wrapper .img-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 88px;
  min-height: 88px;
  padding: 14px 0;
  overflow: hidden;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.24);
  border: solid 2px transparent;
  border-radius: 50%;
}

#publish .modal-body .icons-wrapper .img-wrapper:hover {
  box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.24);
}

#publish .modal-body .icons-wrapper .img-wrapper.active,
#publish .modal-body .icons-wrapper .img-wrapper:active,
#publish .modal-body .icons-wrapper .img-wrapper:focus {
  box-shadow: 0 0 16px 0 rgba(129, 51, 219, 0.4);
  border: solid 2px #1cb7f2;
}

#publish .modal-body .icons-wrapper .img-wrapper img {
  width: 100%;
  max-width: 56px;
}

#publish .modal-footer {
  display: block;
}

#publish #vid-details {
  display: none;
}

#publish .modal-footer p {
  font-weight: 700;
  line-height: 2.29;
  color: #000000;
}

#publish .modal-footer p::after,
#publish .modal-footer p::before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  width: 230px;
  height: 1px;
  background-color: #cccccc;
}

#publish .modal-footer p::after {
  left: unset;
  right: 0;
}

#publish .modal-footer input,
#publish .modal-footer textarea,
#publish .modal-footer #sel-acc {
  font: 400 14px/14px "SourceSansPro", sans-serif;
  color: #d19df8;
  max-width: 420px;
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(129, 51, 219, 0.55);
  border: solid 1px #1cb7f2;
  padding: 15px 16px;
  outline: none;
}

#publish .modal-body #copy-link {
  font-weight: 700;
  max-width: 160px;
  padding: 14px;
  box-shadow: 0 2px 4px 0 rgba(155, 51, 233, 0.35);
  border: solid 2px #1cb7f2;
}

#publish .modal-footer textarea,
#publish .modal-footer#vid-details input {
  padding: 27px 16px 10px;
  color: #000;
}

#publish .modal-footer textarea:hover,
#publish .modal-footer#vid-details input:hover,
#publish .modal-footer #sel-acc:hover,
#publish .modal-footer textarea:focus,
#publish .modal-footer#vid-details input:focus,
#publish .modal-footer #sel-acc:focus {
  border: solid 1px #9b33e9;
  box-shadow: 0 2px 4px 0 rgba(155, 51, 233, 0.5);
}

#publish .modal-footer textarea {
  height: 112px;
}

#publish #vid-details input~.label,
#publish .modal-footer textarea~.label,
#publish .modal-footer #sel-acc~.label {
  color: #d19df8;
  font: 600 14px/14px "SourceSansPro", sans-serif;
  position: absolute;
  top: 50%;
  left: 16px;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
}

#publish .modal-footer textarea~.label {
  top: 19px;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  transform: translateY(0);
}

#publish .modal-footer textarea:focus~.label,
#publish .modal-footer textarea.has-value~.label {
  top: 10px;
}

#publish #vid-details input:focus~.label,
#publish .modal-footer #sel-acc~.label.up,
#publish .modal-footer #sel-acc.has-value~.label,
#publish #vid-details input.has-value~.label {
  top: 10px;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  transform: translateY(0);
}

#publish .modal-footer #sel-acc {
  font-weight: 600;
  color: #000000 !important;
  text-align: left;
  padding: 28px 16px 12px;
  z-index: 9;
  background-color: transparent;
}

#publish .modal-footer #sel-acc~.icon {
  position: absolute;
  top: 50%;
  right: 16px;
  color: #1cb7f2 !important;
  -webkit-transform: translateY(-50%) rotateX(0);
  -moz-transform: translateY(-50%) rotateX(0);
  transform: translateY(-50%) rotateX(0);
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

#publish .modal-footer #sel-acc~.icon.up {
  -webkit-transform: translateY(-50%) rotateX(180deg);
  -moz-transform: translateY(-50%) rotateX(180deg);
  transform: translateY(-50%) rotateX(180deg);
}

#publish .modal-footer #sel-acc::after {
  content: unset;
}

#publish .modal-footer #sel-acc~.dropdown-menu {
  box-shadow: 0 1px 2px 0 #e8c9ff;
  border: solid 1px #d19df8;
  background-color: rgba(255, 255, 255, 0.85);
  padding: 4px 16px 0;
}

#publish .modal-footer #sel-acc~.dropdown-menu li {
  align-items: center;
  padding: 12px 0;
  border-bottom: solid 1px #e8c9ff;
}

#publish .modal-footer #sel-acc~.dropdown-menu li:last-child {
  border: 0;
}

#publish .modal-footer #sel-acc~.dropdown-menu li::after {
  content: "";
  display: block;
  background: url(../images/right-arrow-2.svg) center/16px no-repeat;
  width: 16px;
  height: 16px;
  opacity: 0.4;
}

#publish .modal-footer #sel-acc~.dropdown-menu li:hover::after {
  opacity: 1;
}

#publish .modal-footer #sel-acc~.dropdown-menu li img {
  width: 6.22%;
  margin-right: 8px;
}

#publish .modal-footer #sel-acc~.dropdown-menu li a {
  font: 600 14px / normal "SourceSansPro", sans-serif;
  color: #000000;
  margin-left: 8px;
  outline: none;
}

#publish .modal-footer a:hover,
#publish .modal-footer #sel-acc~.dropdown-menu li:hover a {
  color: #ff949f !important;
}

/*End Publish Modal*/

/*Toaster*/
#toast-container.toast-top-center {
  top: 104px;
  z-index: 9;
}

#toast-container.toast-top-center>.toast {
  width: 100%;
  max-width: 1160px;
  text-align: center;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(83, 96, 107, 0.5);
  background-image: none !important;
  display: flex;
  justify-content: center;
}

#toast-container.toast-top-center>.toast-success {
  border: solid 1px #01803f;
  background-color: #ebf5f0;
}

#toast-container.toast-top-center>.toast-error {
  border: solid 1px #a2000f;
  background-color: #f8ebec;
}

#toast-container>.toast .toast-message:before {
  content: "";
  font-family: FontAwesome;
  font-size: 24px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(-36px, -50%);
}

#toast-container>.toast-success .toast-message::before {
  content: "\f058";
}

#toast-container>.toast-error .toast-message::before {
  content: "\f06a";
}

#toast-container .toast-message {
  font: 700 16px/16px "Montserrat", sans-serif;
}

#toast-container>.toast-success .toast-message {
  color: #01803f;
}

#toast-container>.toast-error .toast-message {
  color: #a2000f;
}

/*End Toaster*/

/*>>> Responsive <<<*/

/* Screen-size 1500 */
@media screen and (max-width: 1500px) {
  #project-section .card-wrapper {
    max-width: 320px;
    margin: 0 auto;
  }

  #project-section .status,
  #project-section .status .proj_img {
    min-height: 180px;
    max-height: 180px;
    margin: 0 auto;
  }

  #feature-section .row-1 img {
    margin-left: 0px;
  }

  #feature-section .row-3 img {
    transform-origin: right;
    margin-left: 0;
  }
}

/* Screen-size 1199 */
@media screen and (max-width: 1199px) {
  br {
    display: none;
  }

  h1 {
    font-size: 35px;
    line-height: 48px;
  }

  h2 {
    font-size: 26px;
    line-height: 36px;
  }

  h3 {
    font-size: 30px;
    line-height: 40px;
  }

  h4 {
    font-size: 22px;
    line-height: 32px;
  }

  h5 {
    font-size: 20px;
    line-height: 30px;
  }

  h6 {
    font-size: 18px;
    line-height: 28px;
  }

  .big-heading {
    font-size: 64px;
  }

  #watch-video .modal-content {
    max-width: 95%;
  }

  #project-section .status .proj_img,
  #project-section .status {
    max-width: 100%;
    max-height: 300px;
    min-height: 300px;
  }
}

/* Screen-size 991 */
@media screen and (max-width: 991px) {
  .big-heading {
    font-size: 60px;
  }

  .mt-sm-md-icon {
    top: 5px;
  }

  #app-header .navbar-toggler {
    border-color: #1cb7f2;
  }

  #app-nav {
    padding-top: 19px;
  }

  #app-nav .navbar-nav {
    width: 100%;
    border-top: 2px solid rgba(28, 183, 242, 0.4);
    padding-top: 20px;
    max-width: 800px;
    margin: 0 auto;
  }

  #app-nav .navbar-nav li {
    padding: 12px 0;
  }

  #app-header .dropdown-menu {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    transform: translateX(0);
    max-width: 320px;
  }

  #intro-section {
    padding: 190px 0 100px;
  }

  #intro-section .colmn-1 {
    margin: 0 auto;
  }

  #project-section {
    padding-bottom: 60px;
  }

  #feature-section {
    padding: 80px 0;
  }

  #feature-section .row,
  #feature-section .row-2 {
    margin-bottom: 100px;
  }

  #feature-section .col {
    margin: 0 auto;
  }

  #feature-section .row img {
    transform: unset;
  }

  #app-footer #footer-nav,
  #app-footer .inner-row {
    justify-content: center;
  }

  #app-footer .inner-row .social-icons {
    flex-basis: 100%;
    text-align: center;
    margin-bottom: 10px;
  }
}

/* Screen-size 767 */
@media screen and (max-width: 767px) {
  h4 {
    font-size: 20px;
    line-height: 30px;
  }

  .modal-dialog .close img {
    width: 12px;
  }
}

/* Screen-size 640 */
@media screen and (max-width: 640px) {
  h3 {
    font-size: 25px;
    line-height: 35px;
  }

  h6,
  .sub-txt {
    font-size: 18px;
    line-height: 1.6;
  }

  .md-txt {
    font-size: 16px;
  }

  .big-heading {
    font-size: 55px;
  }

  #intro-section {
    padding: 150px 0 80px;
  }
}

/* Screen-size 575 */
@media screen and (max-width: 575px) {
  h3 {
    font-size: 22px;
    line-height: 32px;
  }

  h4 {
    font-size: 18px;
    line-height: 28px;
  }

  p,
  a,
  li,
  span {
    font-size: 14px;
    line-height: 1;
  }

  .container-fluid {
    padding: 0 15px !important;
  }

  .big-heading {
    font-size: 50px;
  }

  .input-dark,
  .bootstrap-tagsinput {
    font-size: 14px;
  }

  #project-section .status .proj_img,
  #project-section .status {
    max-height: 250px;
    min-height: 250px;
  }

  #confirm-del .modal-body {
    padding: 30px 20px;
  }

  #upload-vid-aud .modal-body {
    padding: 30px 40px;
  }

  #footer-nav li a {
    padding: 0px 10px;
  }
}

/* Screen-size 440 */
@media screen and (max-width: 440px) {
  .big-heading {
    font-size: 35px;
  }

  #footer-nav li a {
    font-size: 12px;
  }

  #app-footer .social-icons a {
    font-size: 18px;
  }

  #app-footer .copyright p {
    font-size: 12px;
  }

  p,
  a,
  li,
  span {
    font-size: 14px;
  }

  h3 {
    font-size: 20px;
    line-height: 30px;
  }

  #project-section .status .proj_img,
  #project-section .status {
    max-width: 100%;
    max-height: 180px;
    min-height: 180px;
  }
}

.in-search {
  width: 18px;
  height: 18px;
}

#proj-title-bar #search {
  padding-right: 32px !important;
}

.icon-hover-effect:hover {
  filter: brightness(0.1) sepia(1) saturate(10000%) !important;
  opacity: 0.7 !important;
}

.visibility-hidden {
  display: none !important;
}

.soial-rectangle {
  height: 120px;
  border-radius: 8px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.4), 0 0 20px 0 rgba(0, 0, 0, 0.4);
  background-color: #ffffff;
}

.fs-40 {
  font-size: 40px !important;
}

.card-bg-img {
  background-image: url("../images/travel-the-heaven.png");
  background-size: cover;
  background-position: top center;
}

.profile-notification {
  width: 14px;
  height: 13px;
  border: solid 3px #000000;
  background-color: #f84440;
  position: absolute;
  top: 0;
  right: 0;
}

.status-img {
  width: 38px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -15px;
}

.status-transcript-failed {
  background-color: #f84440 !important;
  flex-wrap: wrap;
  text-align: center;
}

.status-rendring-failed {
  background-image: linear-gradient(150deg,
      #17d583 -11%,
      #00d2b3 39%,
      #5bc1de 59%,
      #1cb7f2 78%) !important;
  flex-wrap: wrap;
  text-align: center;
}

.text-white {
  color: white !important;
}

#project-section .status.rendering p,
#project-section .status.transcribing.status-transcript-failed p,
#project-section .status.rendering p,
#project-section .status.transcribing.status-rendring-failed p {
  color: white !important;
}

.profile-oval {
  width: 132px;
  height: 129px;
  border: dashed 3px #cccccc;
  margin: auto;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bg-profile {
  background: #1a1c2d;
  background: linear-gradient(to right, #1a1c2d 0%, #050606 100%);
  background: -moz-linear-gradient(left, #1a1c2d 0%, #050606 100%);
  background: -webkit-linear-gradient(left, #1a1c2d 0%, #050606 100%);
  background: linear-gradient(left, #1a1c2d 0%, #050606 100%);
}

.membership-level-text {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  min-width: 131px;
  height: 31px;
  border-radius: 4px;
  background-color: #ef4949;
  padding: 05px 14px;
  border: none;
}

.personal-info-text {
  height: 29px;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}

.profile-user-name {
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
}

.profile-info-box {
  min-height: 272px;
  border-radius: 8px;
  box-shadow: 0 2px 10px 0 #000000;
  background: #1a1c2d;
  background: linear-gradient(to right, #1a1c2d 0%, #050606 100%);
  background: -moz-linear-gradient(left, #1a1c2d 0%, #050606 100%);
  background: -webkit-linear-gradient(left, #1a1c2d 0%, #050606 100%);
  background: linear-gradient(left, #1a1c2d 0%, #050606 100%);
  padding: 48px 48px;
}

.profile-title {
  color: #000 !important;
  font-size: 14px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 22px;
  letter-spacing: normal;
  text-align: justify;
}

.btn-download {
  min-width: 216px;
  min-height: 40px;
  border-radius: 2px;
  background-color: #00d2b3;
  color: white;
  font-size: 12px;
}

.btn-download:hover,
.btn-download:active {
  background-color: #00d2b3ea;
  color: white;
}

.btn-deactiavate {
  min-width: 216px;
  min-height: 40px;
  border-radius: 2px;
  background-color: #efad4e;
  color: white;
  font-size: 12px;
}

.btn-deactiavate:hover,
.btn-deactiavate:active {
  background-color: #faa124;
  color: white;
}

.btn-delete {
  min-width: 216px;
  min-height: 40px;
  border-radius: 2px;
  background-color: #f84440;
  color: white;
  font-size: 12px;
}

.btn-delete:hover,
.btn-delete:active {
  background-color: #f72421;
  color: white;
}

.warning-text {
  color: #f84440 !important;
}

.support-link-text {
  color: #1cb7f2 !important;
}

.form-group .input-label {
  line-height: 1;
  position: relative;
  display: flex;
  top: 27px;
  margin-left: 9px;
  margin-right: 1px;
  z-index: 2;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #747474 !important;
  font-size: 10px;
  font-weight: bold;
}

.form-row {
  top: -10px;
}

.update-pass {
  font-size: 12px;
  height: 40px;
  padding: 0;
  margin: 0;
}

.password-input {
  letter-spacing: 4px !important;
}

.gdpr-warning-text {
  font-family: SourceSansPro;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #262626;
}

.fs-12 {
  font-size: 12px;
}

.avatar-image {
  width: 40px;
  height: 40px;
}

#app-nav .navbar-nav {
  color: #ef4949;
}

#app-footer .social-icons a .fa-instagram:hover,
#app-footer .social-icons a .fa-twitter:active {
  color: #fb3958 !important;
}

#app-footer .social-icons a .fa-twitter:hover,
#app-footer .social-icons a .fa-twitter:active {
  color: #ef4949 !important;
}

#app-footer .social-icons a .fa-facebook:hover,
#app-footer .social-icons a .fa-facebook:active {
  color: #3b5998 !important;
}

@media screen and (min-width: 99px) {
  #watch-video .modal-dialog {
    height: 75vh !important;
  }
}

/* add and remove this class to respectively show and hide notification  */

.avatar-notification {
  position: absolute;
  width: 16px;
  height: 16px;
  border: solid 3px #000000;
  background-color: #f84440;
  border-radius: 50%;
  right: -0.1px;
  cursor: pointer;
}

.profile-border {
  border: solid 2px #ffffff;
}

.profile-page-avatar {
  background-size: contain;
  height: 100px;
  width: 100px;
  border-radius: 50%;
  margin-bottom: 20px;
}

.input-show {
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 50%;
  color: white;
}

@media screen and (min-width: 991px) {
  .dropdown-menu {
    left: -39%;
  }
}

.dropdown-menu-arrow:before,
.dropdown-menu-arrow:after {
  content: "";
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-width: 9px 10px;
  border-style: solid;
  border-color: transparent;
  z-index: 1001;
  left: 150px;
}

.dropdown-menu-arrow:after {
  top: -18px;
  right: -8px;
  border-bottom-color: rgba(255, 255, 255, 0.9);
}

.dropdown-menu-arrow:before {
  top: -17px;
  right: -8px;
  border-bottom-color: transparent;
}

.dropdown-menu.show {
  display: block;
  background: rgba(255, 255, 255);
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #cccccc !important;
}

.show-svg {
  filter: invert(100%);
}

.input-account-type {
  border-radius: 8px !important;
  border: solid 1px #ececec;
  color: #fff !important;
  background-color: #000 !important;
  height: 56px;
}

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
  width: 24px;
  height: 24px;
}

.team-member-label {
  top: -7px;
  left: 6px;
}

.table td,
.table th {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 0px solid #dee2e6 !important;
  font-family: SourceSansPro;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 0px solid #dee2e6 !important;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #747474;
}

.tbody-bg {
  border-radius: 8px !important;
  background-color: transparent;
}

tr {
  height: 24px;
  text-align: center;
  font-size: 14px;
}

th {
  color: #747474;
  text-transform: uppercase;
  font-family: Poppins;
  font-size: 12px !important;
  font-weight: 600;
}

.accnt-mngmt-btn {
  max-width: 160px !important;
  height: 40px !important;
  font-size: 12px;
  line-height: 0px;
}

.modal-footer button {
  height: 40px;
  line-height: 0;
  font-family: sans-serif;
  font-size: 12px;
  font-weight: 600;
}

.bg-table {
  min-height: 272px;
  border-radius: 8px;
  /* box-shadow: 0 2px 10px 0 #1E1E1E; */
  background: #1a1c2d;
  background: linear-gradient(to right, #1a1c2d 0%, #050606 100%);
  background: -moz-linear-gradient(left, #1a1c2d 0%, #050606 100%);
  background: -webkit-linear-gradient(left, #1a1c2d 0%, #050606 100%);
  background: linear-gradient(left, #1a1c2d 0%, #050606 100%);
  padding: 48px 48px;
  border-radius: 8px;
  box-shadow: 0 2px 10px 0 #000000;
}

.Account-Management-headings {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  text-align: left;
}

.input-dark-accnt,
.bootstrap-tagsinput {
  font: 400 16px/1.5 "SourceSansPro", sans-serif;
  color: #fff !important;
  padding: 22px 10px 14px;
  border-radius: 8px !important;
  border: solid 1px #ececec;
  background-color: #000 !important;
  resize: none;
  height: 56px;
  letter-spacing: 1px;
}

@media screen and (max-width: 991px) {
  .table-margin-top {
    margin-top: -70px !important;
  }
}

@media screen and (max-width: 767px) {
  .text-overflow {
    width: 84px;
    text-overflow: ellipsis;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    font-size: 11px !important;
  }

  .table-margin-top {
    margin-top: -60px !important;
  }

  td {
    font-size: 11px !important;
  }
}

@media screen and (max-width: 547px) {
  .text-overflow {
    width: 98px;
    text-overflow: ellipsis;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
  }

  .bg-table-sm {
    padding: 15px;
  }

  td {
    font-size: 10px;
    color: white;
  }

  .table-margin-top {
    margin-top: -60px !important;
  }

  .team-member-label {
    font-size: 12px;
  }

  .mt-sm-md-icon {
    top: 5px;
  }
}

tr.hide-table-padding td {
  padding: 0;
  height: 0px !important;
}

.expand-button {
  position: relative;
}

.accordion-toggle .expand-button:after {
  position: absolute;
  left: 0.75rem;
  top: 50%;
  transform: translate(0, -50%);
  content: "";
}

.accordion-toggle.collapsed .expand-button:after {
  content: "";
}

/* 
  .pos-rel {
	  position: relative !important;
  }
  */

/* .pos-abs{
	  top: 0;
	  position: absolute !important;
	  left: 10px !important;
  }  */

.input-label-pos {
  display: flex;
  line-height: 1;
  position: relative;
  top: 27px;
  z-index: 2;
  margin-left: 11px;
  margin-right: 3px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #747474 !important;
  font-size: 10px;
  font-weight: bold;
}

ul {
  list-style-type: none;
}

.ul-class li {
  padding-left: 10px;
  padding-right: 20px;
}

.li-span {
  left: 12px !important;
  display: inline-block !important;
  top: -8px !important;
}

.p-accnt {
  padding: 6px;
}

.styled-select select {
  -moz-appearance: none;
  /* Firefox */
  -webkit-appearance: none;
  /* Safari and Chrome */
  appearance: none;
}

input[type="text"]:focus {
  color: turquoise;
}

.profile-title-gdpr {
  font-family: SourceSansPro;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: #000;
}

.accordion-toggle {
  border: solid 1px #424344;
}

.td-br {
  border-right: solid 1px #424344;
}

::-webkit-scrollbar-thumb {
  background-color: #9e9e9e;
  border-radius: 4px;
}

::-webkit-scrollbar {
  width: 2px;
  height: 2px;
  background-color: transparent;
}

.form-group-mr-profile {
  margin-bottom: -8px !important;
}

#proj-title-bar #search {
  border-radius: 4px;
  background-color: #ececec;
  padding: 7.5px 13px;
  color: #171717;
  font: 600 14px/1.36 "SourceSansPro", sans-serif;
  height: auto;
  width: 100%;
  max-width: 453px;
  border: solid 1px #424344;
}

.status-badge {
  width: 131px;
  height: 31px;
  border-radius: 4px;
  background-color: #1cb7f2;
}

.notification-text {
  text-align: left !important;
  padding-right: 5px !important;
}

.badges {
  width: 131px;
  display: flex;
  min-height: 31px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  color: #ffffff;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}

.active-badges {
  background-color: #1cb7f2;
}

.success-badges {
  background-color: #17d583;
}

.failed-badges {
  background-color: #f84440;
}

.hr-tr {
  background-color: white;
  padding-left: 33px;
  padding-right: 33px;
}

.notification-status {
  float: right;
  padding-right: 33px;
}

.hr-span {
  border-bottom: 3px solid white !important;
}

.table-p {
  padding-right: 31px;
  padding-left: 33px;
}

.table-notification td,
.table-notification th {
  padding: 24px 0px;
}

.table-border-btm tr {
  border-bottom: solid 1px #363839;
}

.table-border-btm :last-child {
  border-bottom: solid 1px transparent;
}

.time-info-text {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #747474;
  display: block;
}

.input-search {
  min-height: 48px;
}

.mby-100 {
  min-height: 145px;
}

.no-results {
  vertical-align: middle;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.input-search-svg-ico {
  position: absolute;
  right: 20px;
  top: 32%;
}

.hide-table-padding {
  height: 0px;
}

.section-topmargin {
  padding-top: 80px;
}

.title-header-bar {
  background: #1a1c2d !important;
  height: 64px;
  padding-top: 10px !important;
}

.proj-bar-search-icon {
  left: -35px;
  top: 9px;
  color: #fff !important;
}

.tab-pane {
  min-height: 50vh;
}

/* .nav-sidebar-bg {
  top: 11px;
} */

#imageContainer {
  display: flex;
  justify-content: space-around;
  width: 100%;
  height: 200px !important;
  flex-wrap: wrap !important;
  overflow: auto !important;
}

#editor-nav .nav-link {
  padding: 15.85px 0;
  color: #747474;
  font: 600 14px / normal "ProximaNova", sans-serif;
  border-radius: 0;
}

#editor-nav .nav-link span {
  color: currentColor !important;
  transition: none !important;
}

#editor-nav .nav-link.active,
#editor-nav .nav-link:hover {
  background-image: -webkit-linear-gradient(150deg,
      #17d583 -11%,
      #00d2b3 39%,
      #5bc1de 59%,
      #1cb7f2 78%);
  background-image: linear-gradient(150deg,
      #17d583 -11%,
      #00d2b3 39%,
      #5bc1de 59%,
      #1cb7f2 78%);
  color: #fff !important;
}

#add-proj {
  width: 160px;
  height: 40px;
  border-radius: 2px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#app-editor-header {
  padding: 17px 0;
}

#app-editor-header .btn-wrapper .btn,
#app-editor-header .btn-wrapper span {
  font: 600 14px / normal "SourceSansPro", sans-serif;
  padding: 8px;
  max-width: 132px;
}

#app-editor-header #nxt:hover {
  background-color: #fff;
  color: #8133db !important;
}

#app-editor-header #nxt:hover span {
  color: #8133db !important;
}

#editor-section .colmn-1 {
  max-width: 100px;
  -webkit-box-shadow: 2px 0 20px 0 rgba(0, 0, 0, 0.5);
  box-shadow: 2px 0 20px 0 rgba(0, 0, 0, 0.5);
  z-index: 5;
}

#editor-nav .nav-link {
  padding: 15.85px 0;
  color: #747474;
  font: 600 14px / normal "ProximaNova", sans-serif;
  border-radius: 0;
}

#editor-nav .nav-link.active,
#editor-nav .nav-link:hover {
  background-image: -webkit-linear-gradient(150deg,
      #17d583 -11%,
      #00d2b3 39%,
      #5bc1de 59%,
      #1cb7f2 78%);
  background-image: linear-gradient(150deg,
      #17d583 -11%,
      #00d2b3 39%,
      #5bc1de 59%,
      #1cb7f2 78%);
  color: #fff !important;
}

#editor-nav .nav-link span {
  color: currentColor !important;
  transition: none !important;
}

#editor-tab-wrap #script-tab-cont {
  padding: 40px 0 32px;
}

#script-tab-cont .inner-row .inner-col-1 {
  padding: 0 32px 0 24px;
  border-right: 1px solid #363839;
}

#editor-tab-wrap #script-form label {
  font: 600 14px / normal "SourceSansPro", sans-serif;
  color: #747474;
  margin-bottom: 0;
}

#editor-tab-wrap #vid-script {
  min-height: 766px;
  max-height: 766px;
  overflow: auto;
}

#editor-tab-wrap #vid-script ul li {
  font: 400 16px/2.3 "SourceSansPro", sans-serif;
  color: #fff;
}

#editor-tab-wrap #vid-script ul li small {
  font-size: 12px;
  color: #d19df8;
}

#script-tab-cont .inner-row .inner-col-2 {
  padding: 0 24px 0 32px;
}

#script-tab-cont .inner-row .inner-col-2 .card {
  border: solid 1px #363839;
  background-color: #262626;
  border-radius: 0;
}

#script-tab-cont .plyr::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: -webkit-linear-gradient(to bottom,
      rgba(0, 0, 0, 0),
      #000000);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), #000000);
  z-index: 1;
}

#script-tab-cont .plyr .plyr__controls {
  background: transparent;
}

#script-tab-cont .card .plyr__controls {
  justify-content: flex-start;
}

#script-tab-cont .card .plyr__controls__item:first-child {
  margin-right: 0;
}

#script-tab-cont .card .plyr__volume {
  margin-left: 1px !important;
}

#script-tab-cont .card .plyr__time {
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}

#script-tab-cont .card .plyr__time--duration {
  margin-right: auto;
}

#script-tab-cont .card .card-body {
  padding: 32px 16px 16px;
}

#script-tab-cont .card .card-body .date-wrapper {
  border-bottom: 1px solid #363839;
}

#script-tab-cont .card .card-body p,
#script-tab-cont .card .card-body p .date,
#script-tab-cont .card .card-body label {
  color: #747474;
  font: 600 14px / normal "SourceSansPro", sans-serif;
  margin: 0;
}

#script-tab-cont .card .card-body p .date,
#script-tab-cont .card .card-body .f-name {
  font-weight: 400;
  display: block;
  font-size: 16px;
}

#editor-tab-cont.active {
  display: flex;
}

#editor-tab-cont {
  /* flex-flow: column wrap; */
  align-items: flex-start;
  height: calc(100vh - 340px);
  overflow-y: scroll;
}

#editor-tab-cont #inner-tab-cont {
  box-shadow: 2px 0 20px 0 rgba(0, 0, 0, 0.5);
  background-color: #262626;
  width: 100%;
}

#editor-tab-cont #inner-tab-cont {
  order: 1;
  max-width: 22.389%;
  min-height: 690px;
  max-height: 690px;
  overflow-y: auto;
  padding: 24px 20px 34px;
  z-index: 1;
}

#editor-tab-cont .nav-wrapper {
  order: 2;
  max-width: 77.611%;
}

#editor-tab-cont .nav-wrapper #inner-tab {
  border: 0;
  justify-content: center;
  box-shadow: 2px 0 20px 0 rgba(0, 0, 0, 0.5);
  background-color: #262626;
}

#editor-tab-cont .nav-wrapper #inner-tab li a {
  padding: 14px 28.73px 13px;
  width: 100%;
  border: 0;
  font-weight: 500;
  font-size: 14px;
  color: #747474;
  border-radius: 0;
  background: transparent;
  text-align: center;
}

#editor-tab-cont .nav-wrapper #inner-tab li a.active,
#editor-tab-cont .nav-wrapper #inner-tab li a:hover,
#editor-tab-cont .nav-wrapper #inner-tab li a.active span,
#editor-tab-cont .nav-wrapper #inner-tab li a:hover span,
#editor-tab-cont #media-tabs .nav-link.active,
#editor-tab-cont #media-tabs .nav-link:hover {
  color: #ffffff !important;
}

#editor-tab-cont .nav-wrapper #inner-tab li a::after,
#editor-tab-cont #media-tabs .nav-link:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: transparent;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

#editor-tab-cont .nav-wrapper #inner-tab li a.active::after,
#editor-tab-cont .nav-wrapper #inner-tab li a:hover::after,
#editor-tab-cont #media-tabs .nav-link.active::after,
#editor-tab-cont #media-tabs .nav-link:hover::after {
  background-color: #8133db;
}

#editor-tab-cont .label {
  display: block;
  font: 600 12px / normal "Montserrat", sans-serif;
}

#editor-tab-cont .text-customization select,
#editor-tab-cont .text-to-spch select,
#editor-tab-cont input {
  padding: 7px 12px;
  font: 600 14px / normal "SourceSansPro", sans-serif;
}

#editor-tab-cont .text-customization select,
#editor-tab-cont .text-to-spch select {
  -webkit-appearance: none;
  cursor: pointer;
}

#editor-tab-cont .text-customization select~.icon,
#editor-tab-cont .text-to-spch select~.icon,
#editor-tab-cont .text-customization input~.icon {
  position: absolute;
  top: 50%;
  right: 15px;
  line-height: 0;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
}

#editor-tab-cont .text-customization .sel-wrapper {
  flex-basis: 66.925%;
}

#editor-tab-cont .text-customization .input-wrapper {
  flex-basis: 29.975%;
  flex-grow: 1;
}

#editor-tab-cont .text-customization .txt-edit-btn {
  flex-basis: 16.666%;
  padding: 0;
  margin: 0 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 17.666%;
  min-height: 36px;
}

#editor-tab-cont .text-alignment .input-group-prepend {
  flex-grow: 1;
  flex-basis: 33.336%;
}

#editor-tab-cont .text-alignment .input-group-prepend .icon {
  position: absolute;
  top: 50%;
  left: 10px;
  z-index: 1;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
  color: #747474;
}

#editor-tab-cont .text-customization #t-lineheight {
  padding-left: 32px;
}

#editor-tab-cont .input-color {
  flex-basis: 38.466%;
  margin-right: 7px;
  margin-bottom: 8px;
}

#editor-tab-cont #t-bg-btn,
#editor-tab-cont #t-color-btn,
#editor-tab-cont #bg-color-btn,
#editor-tab-cont #bg-grad-btn {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 50%;
  left: 8px;
  border-radius: 3px;
  border: solid 2px rgba(255, 255, 255, 0.5);
  z-index: 1;
  -webkit-transform: translateY(-85%);
  -moz-transform: translateY(-85%);
  transform: translateY(-85%);
  transition: none !important;
}

#editor-tab-cont #tBg-hex,
#editor-tab-cont #tColor-hex,
#editor-tab-cont #bgColor-hex,
#editor-tab-cont #bgGrad-hex {
  padding-left: 40px;
}

#editor-tab-cont .text-color label,
#editor-tab-cont .text-bg label,
#editor-tab-cont .bg-color label,
#editor-tab-cont .bg-grad label,
#editor-tab-cont .text-to-spch label {
  display: block;
  position: relative;
  color: #747474;
  font: 600 10px / normal "SourceSansPro", sans-serif;
  left: 0;
  top: 0;
  margin: 2px 0 0;
}

#editor-tab-cont .rgb-wrapper {
  flex-basis: 17.57%;
  margin-right: 8px;
  margin-bottom: 8px;
}

#editor-tab-cont .rgb-wrapper input {
  padding: 7px 0;
  text-align: center;
}

#editor-tab-cont .no-color,
#editor-tab-cont .swatch {
  width: 28px;
  height: 28px;
  border: 0;
  border-radius: 2px;
  padding: 0;
  margin-right: 1px;
  flex-basis: 10.775%;
  margin-bottom: 1px;
  overflow: hidden;
}

#editor-tab-cont .no-color::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 2px;
  height: 40px;
  background: #a2000f;
  transform: rotate(45deg) translate(5px, -13px);
}

#editor-tab-cont .custom-switch .custom-control-label::before {
  top: 0;
  left: 0;
  width: 48px;
  height: 24px;
  border: 1px solid #424344;
  border-radius: 83px;
  background-color: #000;
  -webkit-transform: translateX(-48px);
  -moz-transform: translateX(-48px);
  transform: translateX(-48px);
}

#editor-tab-cont .custom-control-input:focus~.custom-control-label::before {
  box-shadow: none;
}

#editor-tab-cont .custom-switch .custom-control-label::after {
  top: 1.9px;
  left: -46px;
  width: 20px;
  height: 20px;
  background-color: #fff;
  border-radius: 50%;
}

#editor-tab-cont .custom-control-input:checked~.custom-control-label::before {
  border-color: #747474;
  background-color: blueviolet;
  background-image: -webkit-linear-gradient(137deg,
      #2c32b8 -8%,
      #7033d5 34%,
      #8133db 51%,
      #9933e6 68%);
  background-image: linear-gradient(137deg,
      #2c32b8 -8%,
      #7033d5 34%,
      #8133db 51%,
      #9933e6 68%);
}

#editor-tab-cont .custom-switch .custom-control-input:checked~.custom-control-label::after {
  -webkit-transform: translateX(25px);
  transform: translateX(25px);
}

#editor-tab-cont .preview-wrapper {
  display: block;
  width: 100%;
  max-width: 896px;
  order: 3;
  margin: 24px auto 0;
}

#editor-tab-cont .preview-wrapper .controls {
  background-color: #000;
  padding: 12px 0 12px;
}

#editor-tab-cont .time-wrapper .current-time,
#editor-tab-cont .time-wrapper .current-time small {
  font-weight: 600;
}

#editor-tab-cont .time-wrapper .duration-time,
#editor-tab-cont .time-wrapper .duration-time small {
  font-weight: 500;
}

#editor-tab-cont .vid-controls .controls-wrapper {
  width: 100%;
  max-width: 248px;
  margin: 0 auto;
}

#editor-tab-cont .card {
  background-color: transparent;
  border: 0;
  padding: 0;
}

#editor-tab-cont .card .card-header {
  padding: 0;
  border: 0;
}

#editor-tab-cont .card .card-header button {
  text-align: left;
  padding-left: 26px !important;
  line-height: 1.4;
}

#editor-tab-cont .card .card-header button::after {
  content: "";
  width: 16px;
  height: 16px;
  background-color: #ffffff;
  border: solid 4px #8133db;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
}

#editor-tab-cont .card .card-header button.collapsed::after {
  background-color: rgba(255, 255, 255, 0.5);
  border: solid 2px #ffffff;
}

#editor-tab-cont #bg .card .color-range #grad-color {
  width: 100%;
  height: 8px;
  border-radius: 4px;
}

#editor-tab-cont #bg .card .color-range .color-stop {
  width: 8px;
  height: 8px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
  border: solid 1.5px #fff;
  border-radius: 50%;
  position: absolute;
  top: 50% !important;
  left: 0;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 1;
  cursor: pointer;
}

#editor-tab-cont #bg .card .color-stop.rght-marker {
  left: 100%;
  transform: translate(-100%, -50%);
}

#editor-tab-cont #bg .card .color-stop.active {
  width: 12px;
  height: 12px;
}

#editor-tab-cont .search-img-media~.input-group-prepend,
#editor-tab-cont .search-vid-media~.input-group-prepend,
#editor-tab-cont .search-audio-media~.input-group-prepend {
  color: #747474;
  right: 12px;
  z-index: 4;
}

#editor-tab-cont .up-img-btn,
#editor-tab-cont .up-vid-btn {
  font: 600 14px / normal "SourceSansPro", sans-serif;
  padding: 8px 16px 8px 30px;
  max-width: 133px;
  border-radius: 4px;
}

#editor-tab-cont .up-img-btn .ico,
#editor-tab-cont .up-vid-btn .ico {
  left: 13px;
}

#editor-tab-cont .results-wrapper {
  max-height: 265px;
  overflow-y: auto;
  margin-bottom: 16px;
  justify-content: center;
  align-items: flex-start;
}

#editor-tab-cont .results-wrapper a {
  flex-basis: 50%;
  padding: 2px;
}

#editor-tab-cont .results-wrapper a img {
  border: 2px solid transparent;
  width: 100%;
  min-height: 75px;
  max-height: 75px;
  object-fit: cover;
}

#editor-tab-cont .results-wrapper a:hover img {
  border-color: #d75348;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #9e9e9e;
  border-radius: 4px;
}

#media-tabs .nav-link {
  color: #747474;
  background: no-repeat;
  border: none;
  padding: 0 21px 11px;
}

#editor-tab-cont #media-tab-cont .results-wrapper {
  min-height: 454px;
}

#editor-tab-cont #media-tab-cont #vid-clips .vid-time {
  font: 600 9px / normal "SourceSansPro", sans-serif;
  color: #000;
  border-radius: 3px;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 4px;
  position: absolute;
  top: 8px;
  left: 8px;
}

#editor-tab-cont #audio .aud-wrapper {
  width: 100%;
  max-width: 80px;
  min-height: 80px;
  background-image: linear-gradient(155deg,
      #2c32b8 -13%,
      #7033d5 41%,
      #8133db 63%,
      #9933e6 84%);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-basis: 30.23%;
  margin: 4px;
}

#editor-tab-cont #audio .aud-ctrl-btn {
  color: #7033d5;
  background-color: #fff;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

#editor-tab-cont #audio #bg-music-collapse .results-wrapper {
  min-height: 300px;
}

#editor-tab-cont #audio .btn-wrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  justify-content: center;
}

#editor-tab-cont #audio .btn-wrapper button {
  font: 600 14px/14px "SourceSansPro", sans-serif;
  padding: 10px;
  border-radius: 4px;
  max-width: 128px;
}

#editor-tab-cont #audio .btn-wrapper #upload-audio {
  padding-left: 25px;
}

#editor-tab-cont #audio .btn-wrapper button .ico {
  color: #d19df8;
  left: 12px;
}

#editor-tab-cont .text-to-spch .sel-wrapper {
  flex: 1 1 auto;
}

#editor-tab-cont .text-to-spch .sel-wrapper:first-child {
  flex: 0 0 61px;
}

#editor-tab-cont .text-to-spch select {
  font-size: 12px;
  padding: 7px 8px;
}

#editor-tab-cont .text-to-spch select~.icon {
  top: 35%;
  right: 8px;
}

#editor-tab-cont #enter-script {
  font: 600 12px / normal "SourceSansPro";
  color: #ffffff;
  padding: 11px 12px;
  height: 150px;
}

#editor-tab-cont #enter-script::placeholder {
  color: #747474;
}

#editor-tab-cont #txt-spch-collapse .btn-wrapper,
#editor-tab-cont #cur-audio-collapse .btn-wrapper {
  position: relative;
}

#editor-tab-cont #motion .motion-title p {
  font-weight: 600;
  line-height: 1.14;
  color: #747474;
}

#editor-tab-cont #motion ul li a {
  font: 500 12px/2 "Montserrat", sans-serif;
  display: block;
}

#editor-tab-cont #transition .transition-list .trans-item {
  flex: 1 1 50%;
  padding: 8px 4px;
}

#editor-tab-cont #transition .trans-item .trans-title {
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
  font: 600 10px / normal "Montserrat", sans-serif;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

#editor-tab-cont #transition .transition-list .trans-item img {
  min-height: 95px;
  object-fit: cover;
}

#timeline-row {
  /* position: fixed; */
  left: 0;
  right: 0;
  width: 100%;
  bottom: 0;
  z-index: 9;
  background-color: #171717;
}

#editor-section .timeline-header .timeline-component-zoom {
  padding: 7px;
  border: solid 0.5px #292b2e;
  background-color: #121212;
  flex: 0 0 100px;
}

#editor-section .timeline-wrapper input[type="range"] {
  height: 2px;
  background-color: #fff;
  border-radius: 3px;
  -webkit-appearance: none;
  margin: 0 6px;
  max-width: 56px;
  cursor: pointer;
}

#editor-section .timeline-wrapper input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 10px;
  height: 10px;
  background-color: #8133db;
  border: 2px solid #fff;
  border-radius: 50%;
}

#editor-section .timeline-wrapper input[type="range"]::-moz-range-thumb {
  -webkit-appearance: none;
  width: 6px;
  height: 6px;
  background-color: #8133db;
  border: 2px solid #fff;
  border-radius: 50%;
}

#editor-section .timeline-component-zoom .input-group-append,
#editor-section .timeline-component-zoom .input-group-prepend {
  margin: 0;
  color: #9e9e9e;
}

#editor-section .timeline-header .timeline-component-ruler {
  display: flex;
  border: solid 0.5px #292b2e;
  background-color: #121212;
  padding: 0 0 1px 8px;
  flex: 1 1 auto;
}

#editor-section .timeline-component-ruler .sec {
  position: absolute;
  left: 0;
  top: 0;
  width: 52px;
}

#editor-section .timeline-component-ruler .sec .sec-pin {
  width: 2px;
  height: 8px;
  border-radius: 0.8px;
  background-color: #555555;
}

#editor-section .timeline-component-ruler .sec .sec-time {
  display: block;
  font: 500 8px / normal "ProximaNova", sans-serif;
  color: #555555;
  margin-top: 4px;
}

#editor-section .timeline-header .timeline-component-pin {
  width: 4px;
  height: 15px;
  background-color: #8133db;
  display: block;
  position: absolute;
  top: 0;
  left: 8px;
  cursor: pointer;
  z-index: 99;
}

#editor-section .timeline-header .timeline-component-pin:after {
  content: "";
  display: block;
  width: 1px;
  height: 248px;
  background-color: #8133db;
  position: absolute;
  top: 0;
  left: 2px;
}

#editor-section .timeline-body {
  display: flex;
  /* height: calc(100vh - 244px); */
}

#editor-section .timeline-component-main {
  flex: 1 1 auto;
}

#editor-section .timeline-component-main .timeline-component-track_wrapper {
  /* position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0; */
  overflow-x: scroll;
  overflow-y: visible;
  max-height: 244px;
}

#editor-section .timeline-body .timeline-component-track {
  display: flex;
  align-items: center;
  border: solid 1px #292b2e;
}

#editor-section .timeline-component-track .timeline-track {
  display: flex;
  min-height: 80px;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  flex: 0 0 100px;
  text-align: center;
  padding: 8px;
  border-right: solid 0.5px #292b2e;
}

#editor-section .timeline-component-track .timeline-track .track-title {
  width: 100%;
  font: 500 8px / normal "ProximaNova", sans-serif;
  color: #747474;
  align-self: flex-end;
  margin-bottom: 4px;
}

#editor-section .timeline-component-track .timeline-track .track-btns {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  align-self: flex-start;
}

#editor-section .timeline-component-track .timeline-track .track-btns .ico {
  color: #fff;
  flex-grow: 1;
  cursor: pointer;
}

#editor-section .timeline-component-track .timeline-track .track-btns .ico-hide {
  display: none;
}

#editor-section .timeline-component-track .timeline-track .volume {
  max-width: 84px;
  margin: 0 auto 4px;
}

#editor-section .timeline-component-track_wrapper .timeline-component-add-track {
  display: flex;
  border: solid 0.5px #292b2e;
}

#editor-section .timeline-component-add-track .add-track {
  display: block;
  background-color: #121212;
  font: 500 8px / normal "Montserrat", sans-serif;
  color: #d75348;
  text-align: center;
  padding: 3px;
  flex: 0 0 100px;
  border-right: 1px solid #292b2e;
}

#editor-section .timeline-component-main .timeline-track-layer {
  display: flex;
  align-items: center;
  flex: 1 1 auto;
  border-left: solid 0.5px #292b2e;
  height: 80px;
  padding: 4px 0 4px 8px;
}

#editor-section .timeline-track-layer .timeline-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  height: 100%;
}

#editor-section .timeline-track-layer .selected {
  border: solid 2px #ffcc01;
}

#editor-section .timeline-slide .slide-tile {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 0.5px #cccccc;
  background-color: #ffffff;
}

#editor-section .timeline-slide .slide-tile .slide-title {
  color: #000000;
  font: 600 7px / normal "Montserrat", sans-serif;
}

#editor-section .timeline-track-layer .timeline-transition {
  padding: 16px 1px;
  background-color: #ffcc01;
  color: #000;
}

#editor-section .timeline-track-layer .timeline-audio-track {
  height: 100%;
  background-color: #2ab365;
  display: flex;
}

/*Toaster*/
#vid-script .toast-bottom-center {
  bottom: 330px;
  position: absolute;
}

#vid-script .toast-bottom-center .toast {
  width: 100%;
  max-width: 793px;
  text-align: center;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(83, 96, 107, 0.5);
  border: solid 1px #a2000f;
  background: #f8ebec none !important;
  padding: 23px 16px;
  opacity: 1;
  margin-bottom: 0;
}

#vid-script .toast-bottom-center .toast p {
  font-weight: bold;
  line-height: normal;
  color: #a2000f;
}

#vid-script .toast-bottom-center .toast .btn-wrapper {
  justify-content: center;
  align-items: center;
}

#vid-script .toast-bottom-center .toast .btn-wrapper .btn {
  color: #a2000f;
  padding: 9px;
  font: 600 14px/14px "SourceSansPro", sans-serif;
  max-width: 132px;
  border-radius: 4px;
  border: solid 2px #a2000f;
  box-shadow: 0 2px 4px 0 rgba(162, 0, 15, 0.5);
}

#vid-script .toast-bottom-center .toast .btn-wrapper .btn-3 {
  color: #ffffff;
  background-color: #a2000f;
}

#vid-script .toast-bottom-center .toast .btn-wrapper .btn-3:hover {
  color: #a2000f !important;
  background-color: #ffffff;
}

#vid-script .toast-bottom-center .toast .btn-wrapper .btn-2:hover {
  background-color: #a2000f;
}

/*End Toaster*/

/*>>> Responsive <<<*/

/* Screen-size 1440 */
@media screen and (max-width: 1440px) {
  #script-tab-cont .inner-row .inner-col-1 {
    flex-basis: 67%;
    max-width: 897px;
  }

  #script-tab-cont .inner-row .inner-col-2 {
    flex-basis: 33%;
    max-width: 442px;
  }

  #editor-tab-cont #inner-tab-cont {
    max-width: 303px;
  }

  #editor-tab-cont .nav-wrapper {
    max-width: calc(100vw - 400px);
  }
}

/* Screen-size 1366 */
@media screen and (max-width: 1366px) {
  #editor-tab-cont {
    height: 640px;
  }

  #editor-tab-cont #inner-tab-cont {
    min-height: 640px;
    max-height: 640px;
  }

  #editor-tab-cont .preview-wrapper {
    max-width: 768px;
  }

  #editor-section .timeline-body {
    height: calc(100vh - 736px);
  }
}

/* Screen-size 1199 */
@media screen and (max-width: 1199px) {
  #editor-section .colmn-1 {
    max-width: unset;
    flex: 0 0 100px;
  }

  #editor-section .colmn-2 {
    max-width: unset;
    flex: 1 1 0;
  }

  #script-tab-cont .inner-row .inner-col-1 {
    flex-basis: 62%;
  }

  #script-tab-cont .inner-row .inner-col-2 {
    flex-basis: 38%;
  }

  #editor-tab-cont .text-customization .txt-edit-btn {
    max-width: 14.1%;
  }

  #editor-tab-cont .nav-wrapper {
    overflow-x: scroll;
  }

  #editor-tab-cont .nav-wrapper #inner-tab {
    flex-wrap: nowrap;
    white-space: nowrap;
  }

  #editor-tab-cont .nav-wrapper #inner-tab li a {
    padding: 15px 18px;
  }

  #editor-tab-cont .preview-wrapper {
    max-width: 90%;
  }
}

/* Screen-size 991 */
@media screen and (max-width: 991px) {
  #script-tab-cont .inner-row {
    flex-wrap: wrap;
  }

  #script-tab-cont .inner-row .inner-col-1,
  #script-tab-cont .inner-row .inner-col-2 {
    flex-basis: 90%;
    max-width: 100%;
    padding: 0 20px;
    margin: 0 auto;
  }

  #script-tab-cont .inner-row .inner-col-1 {
    border-right: 0;
    margin-bottom: 30px;
  }

  #editor-tab-wrap #vid-script {
    min-height: unset;
    max-height: unset;
  }

  #vid-script .toast-bottom-center {
    bottom: 20px;
  }
}

/* Screen-size 640 */
@media screen and (max-width: 640px) {

  #script-tab-cont .inner-row .inner-col-1,
  #script-tab-cont .inner-row .inner-col-2 {
    flex-basis: 100%;
    padding: 0 15px;
  }
}

/* Screen-size 575 */
@media screen and (max-width: 575px) {
  #editor-section .container-fluid {
    padding: 0 !important;
  }

  #editor-section .colmn-1 {
    max-width: 100%;
    flex-basis: 100%;
    overflow-x: scroll;
  }

  #editor-nav {
    flex-direction: row !important;
    flex-wrap: nowrap;
    white-space: nowrap;
  }

  #editor-nav .nav-link {
    padding: 15px 25px;
    flex: 1 1 auto;
    margin-bottom: 0 !important;
  }

  #editor-tab-wrap #script-tab-cont {
    padding: 30px 0;
  }

  #script-tab-cont .card .card-body p .date,
  #script-tab-cont .card .card-body .f-name {
    font-size: 14px;
  }
}

.icon-hover-effect:hover {
  filter: brightness(0.1) sepia(1) saturate(10000%) !important;
  opacity: 0.7 !important;
}

@keyframes plyr-progress {
  to {
    background-position: 25px 0;
    background-position: var(--plyr-progress-loading-size, 25px) 0;
  }
}

@keyframes plyr-popup {
  0% {
    opacity: 0.5;
    transform: translateY(10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes plyr-fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.plyr {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  align-items: center;
  direction: ltr;
  display: flex;
  flex-direction: column;
  font-family: inherit;
  font-family: var(--plyr-font-family, inherit);
  font-variant-numeric: tabular-nums;
  font-weight: 400;
  font-weight: var(--plyr-font-weight-regular, 400);
  height: 100%;
  line-height: 1.7;
  line-height: var(--plyr-line-height, 1.7);
  max-width: 100%;
  min-width: 200px;
  position: relative;
  text-shadow: none;
  transition: box-shadow 0.3s ease;
  z-index: 0;
}

.plyr audio,
.plyr iframe,
.plyr video {
  display: block;
  height: 100%;
  width: 100%;
}

.plyr button {
  font: inherit;
  line-height: inherit;
  width: auto;
}

.plyr:focus {
  outline: 0;
}

.plyr--full-ui {
  box-sizing: border-box;
}

.plyr--full-ui *,
.plyr--full-ui ::after,
.plyr--full-ui ::before {
  box-sizing: inherit;
}

.plyr--full-ui a,
.plyr--full-ui button,
.plyr--full-ui input,
.plyr--full-ui label {
  touch-action: manipulation;
}

.plyr__badge {
  background: #4a5464;
  background: var(--plyr-badge-background, #4a5464);
  border-radius: 2px;
  border-radius: var(--plyr-badge-border-radius, 2px);
  color: #fff;
  color: var(--plyr-badge-text-color, #fff);
  font-size: 9px;
  font-size: var(--plyr-font-size-badge, 9px);
  line-height: 1;
  padding: 3px 4px;
}

.plyr--full-ui ::-webkit-media-text-track-container {
  display: none;
}

.plyr__captions {
  animation: plyr-fade-in 0.3s ease;
  bottom: 0;
  display: none;
  font-size: 13px;
  font-size: var(--plyr-font-size-small, 13px);
  left: 0;
  padding: 10px;
  padding: var(--plyr-control-spacing, 10px);
  position: absolute;
  text-align: center;
  transition: transform 0.4s ease-in-out;
  width: 100%;
}

.plyr__captions span:empty {
  display: none;
}

@media (min-width: 480px) {
  .plyr__captions {
    font-size: 15px;
    font-size: var(--plyr-font-size-base, 15px);
    padding: calc(10px * 2);
    padding: calc(var(--plyr-control-spacing, 10px) * 2);
  }
}

@media (min-width: 768px) {
  .plyr__captions {
    font-size: 18px;
    font-size: var(--plyr-font-size-large, 18px);
  }
}

.plyr--captions-active .plyr__captions {
  display: block;
}

.plyr:not(.plyr--hide-controls) .plyr__controls:not(:empty)~.plyr__captions {
  transform: translateY(calc(10px * -4));
  transform: translateY(calc(var(--plyr-control-spacing, 10px) * -4));
}

.plyr__caption {
  background: rgba(0, 0, 0, 0.8);
  background: var(--plyr-captions-background, rgba(0, 0, 0, 0.8));
  border-radius: 2px;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  color: #fff;
  color: var(--plyr-captions-text-color, #fff);
  line-height: 185%;
  padding: 0.2em 0.5em;
  white-space: pre-wrap;
}

.plyr__caption div {
  display: inline;
}

.plyr__control {
  background: 0 0;
  border: 0;
  border-radius: 3px;
  border-radius: var(--plyr-control-radius, 3px);
  color: inherit;
  cursor: pointer;
  flex-shrink: 0;
  overflow: visible;
  padding: calc(10px * 0.7);
  padding: calc(var(--plyr-control-spacing, 10px) * 0.7);
  position: relative;
  transition: all 0.3s ease;
}

.plyr__control svg {
  display: block;
  fill: currentColor;
  height: 18px;
  height: var(--plyr-control-icon-size, 18px);
  pointer-events: none;
  width: 18px;
  width: var(--plyr-control-icon-size, 18px);
}

.plyr__control:focus {
  outline: 0;
}

.plyr__control.plyr__tab-focus {
  outline-color: #00b3ff;
  outline-color: var(--plyr-tab-focus-color,
      var(--plyr-color-main, var(--plyr-color-main, #00b3ff)));
  outline-offset: 2px;
  outline-style: dotted;
  outline-width: 3px;
}

a.plyr__control {
  text-decoration: none;
}

a.plyr__control::after,
a.plyr__control::before {
  display: none;
}

.plyr__control.plyr__control--pressed .icon--not-pressed,
.plyr__control.plyr__control--pressed .label--not-pressed,
.plyr__control:not(.plyr__control--pressed) .icon--pressed,
.plyr__control:not(.plyr__control--pressed) .label--pressed {
  display: none;
}

.plyr--full-ui ::-webkit-media-controls {
  display: none;
}

.plyr__controls {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  text-align: center;
}

.plyr__controls .plyr__progress__container {
  flex: 1;
  min-width: 0;
}

.plyr__controls .plyr__controls__item {
  margin-left: calc(10px / 4);
  margin-left: calc(var(--plyr-control-spacing, 10px) / 4);
}

.plyr__controls .plyr__controls__item:first-child {
  margin-left: 0;
  margin-right: auto;
}

.plyr__controls .plyr__controls__item.plyr__progress__container {
  padding-left: calc(10px / 4);
  padding-left: calc(var(--plyr-control-spacing, 10px) / 4);
}

.plyr__controls .plyr__controls__item.plyr__time {
  padding: 0 calc(10px / 2);
  padding: 0 calc(var(--plyr-control-spacing, 10px) / 2);
}

.plyr__controls .plyr__controls__item.plyr__progress__container:first-child,
.plyr__controls .plyr__controls__item.plyr__time+.plyr__time,
.plyr__controls .plyr__controls__item.plyr__time:first-child {
  padding-left: 0;
}

.plyr__controls:empty {
  display: none;
}

.plyr [data-plyr="airplay"],
.plyr [data-plyr="captions"],
.plyr [data-plyr="fullscreen"],
.plyr [data-plyr="pip"] {
  display: none;
}

.plyr--airplay-supported [data-plyr="airplay"],
.plyr--captions-enabled [data-plyr="captions"],
.plyr--fullscreen-enabled [data-plyr="fullscreen"],
.plyr--pip-supported [data-plyr="pip"] {
  display: inline-block;
}

.plyr__menu {
  display: flex;
  position: relative;
}

.plyr__menu .plyr__control svg {
  transition: transform 0.3s ease;
}

.plyr__menu .plyr__control[aria-expanded="true"] svg {
  transform: rotate(90deg);
}

.plyr__menu .plyr__control[aria-expanded="true"] .plyr__tooltip {
  display: none;
}

.plyr__menu__container {
  animation: plyr-popup 0.2s ease;
  background: rgba(255, 255, 255, 0.9);
  background: var(--plyr-menu-background, rgba(255, 255, 255, 0.9));
  border-radius: 4px;
  bottom: 100%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  box-shadow: var(--plyr-menu-shadow, 0 1px 2px rgba(0, 0, 0, 0.15));
  color: #4a5464;
  color: var(--plyr-menu-color, #4a5464);
  font-size: 15px;
  font-size: var(--plyr-font-size-base, 15px);
  margin-bottom: 10px;
  position: absolute;
  right: -3px;
  text-align: left;
  white-space: nowrap;
  z-index: 3;
}

.plyr__menu__container>div {
  overflow: hidden;
  transition: height 0.35s cubic-bezier(0.4, 0, 0.2, 1),
    width 0.35s cubic-bezier(0.4, 0, 0.2, 1);
}

.plyr__menu__container::after {
  border: 4px solid transparent;
  border: var(--plyr-menu-arrow-size, 4px) solid transparent;
  border-top-color: rgba(255, 255, 255, 0.9);
  border-top-color: var(--plyr-menu-background, rgba(255, 255, 255, 0.9));
  content: "";
  height: 0;
  position: absolute;
  right: calc(((18px / 2) + calc(10px * 0.7)) - (4px / 2));
  right: calc(((var(--plyr-control-icon-size, 18px) / 2) + calc(var(--plyr-control-spacing, 10px) * 0.7)) - (var(--plyr-menu-arrow-size, 4px) / 2));
  top: 100%;
  width: 0;
}

.plyr__menu__container [role="menu"] {
  padding: calc(10px * 0.7);
  padding: calc(var(--plyr-control-spacing, 10px) * 0.7);
}

.plyr__menu__container [role="menuitem"],
.plyr__menu__container [role="menuitemradio"] {
  margin-top: 2px;
}

.plyr__menu__container [role="menuitem"]:first-child,
.plyr__menu__container [role="menuitemradio"]:first-child {
  margin-top: 0;
}

.plyr__menu__container .plyr__control {
  align-items: center;
  color: #4a5464;
  color: var(--plyr-menu-color, #4a5464);
  display: flex;
  font-size: 13px;
  font-size: var(--plyr-font-size-menu, var(--plyr-font-size-small, 13px));
  padding-bottom: calc(calc(10px * 0.7) / 1.5);
  padding-bottom: calc(calc(var(--plyr-control-spacing, 10px) * 0.7) / 1.5);
  padding-left: calc(calc(10px * 0.7) * 1.5);
  padding-left: calc(calc(var(--plyr-control-spacing, 10px) * 0.7) * 1.5);
  padding-right: calc(calc(10px * 0.7) * 1.5);
  padding-right: calc(calc(var(--plyr-control-spacing, 10px) * 0.7) * 1.5);
  padding-top: calc(calc(10px * 0.7) / 1.5);
  padding-top: calc(calc(var(--plyr-control-spacing, 10px) * 0.7) / 1.5);
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%;
}

.plyr__menu__container .plyr__control>span {
  align-items: inherit;
  display: flex;
  width: 100%;
}

.plyr__menu__container .plyr__control::after {
  border: 4px solid transparent;
  border: var(--plyr-menu-item-arrow-size, 4px) solid transparent;
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.plyr__menu__container .plyr__control--forward {
  padding-right: calc(calc(10px * 0.7) * 4);
  padding-right: calc(calc(var(--plyr-control-spacing, 10px) * 0.7) * 4);
}

.plyr__menu__container .plyr__control--forward::after {
  border-left-color: #728197;
  border-left-color: var(--plyr-menu-arrow-color, #728197);
  right: calc((calc(10px * 0.7) * 1.5) - 4px);
  right: calc((calc(var(--plyr-control-spacing, 10px) * 0.7) * 1.5) - var(--plyr-menu-item-arrow-size, 4px));
}

.plyr__menu__container .plyr__control--forward.plyr__tab-focus::after,
.plyr__menu__container .plyr__control--forward:hover::after {
  border-left-color: currentColor;
}

.plyr__menu__container .plyr__control--back {
  font-weight: 400;
  font-weight: var(--plyr-font-weight-regular, 400);
  margin: calc(10px * 0.7);
  margin: calc(var(--plyr-control-spacing, 10px) * 0.7);
  margin-bottom: calc(calc(10px * 0.7) / 2);
  margin-bottom: calc(calc(var(--plyr-control-spacing, 10px) * 0.7) / 2);
  padding-left: calc(calc(10px * 0.7) * 4);
  padding-left: calc(calc(var(--plyr-control-spacing, 10px) * 0.7) * 4);
  position: relative;
  width: calc(100% - (calc(10px * 0.7) * 2));
  width: calc(100% - (calc(var(--plyr-control-spacing, 10px) * 0.7) * 2));
}

.plyr__menu__container .plyr__control--back::after {
  border-right-color: #728197;
  border-right-color: var(--plyr-menu-arrow-color, #728197);
  left: calc((calc(10px * 0.7) * 1.5) - 4px);
  left: calc((calc(var(--plyr-control-spacing, 10px) * 0.7) * 1.5) - var(--plyr-menu-item-arrow-size, 4px));
}

.plyr__menu__container .plyr__control--back::before {
  background: #dcdfe5;
  background: var(--plyr-menu-back-border-color, #dcdfe5);
  box-shadow: 0 1px 0 #fff;
  box-shadow: 0 1px 0 var(--plyr-menu-back-border-shadow-color, #fff);
  content: "";
  height: 1px;
  left: 0;
  margin-top: calc(calc(10px * 0.7) / 2);
  margin-top: calc(calc(var(--plyr-control-spacing, 10px) * 0.7) / 2);
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 100%;
}

.plyr__menu__container .plyr__control--back.plyr__tab-focus::after,
.plyr__menu__container .plyr__control--back:hover::after {
  border-right-color: currentColor;
}

.plyr__menu__container .plyr__control[role="menuitemradio"] {
  padding-left: calc(10px * 0.7);
  padding-left: calc(var(--plyr-control-spacing, 10px) * 0.7);
}

.plyr__menu__container .plyr__control[role="menuitemradio"]::after,
.plyr__menu__container .plyr__control[role="menuitemradio"]::before {
  border-radius: 100%;
}

.plyr__menu__container .plyr__control[role="menuitemradio"]::before {
  background: rgba(0, 0, 0, 0.1);
  content: "";
  display: block;
  flex-shrink: 0;
  height: 16px;
  margin-right: 10px;
  margin-right: var(--plyr-control-spacing, 10px);
  transition: all 0.3s ease;
  width: 16px;
}

.plyr__menu__container .plyr__control[role="menuitemradio"]::after {
  background: #fff;
  border: 0;
  height: 6px;
  left: 12px;
  opacity: 0;
  top: 50%;
  transform: translateY(-50%) scale(0);
  transition: transform 0.3s ease, opacity 0.3s ease;
  width: 6px;
}

.plyr__menu__container .plyr__control[role="menuitemradio"][aria-checked="true"]::before {
  background: #00b3ff;
  background: var(--plyr-control-toggle-checked-background,
      var(--plyr-color-main, var(--plyr-color-main, #00b3ff)));
}

.plyr__menu__container .plyr__control[role="menuitemradio"][aria-checked="true"]::after {
  opacity: 1;
  transform: translateY(-50%) scale(1);
}

.plyr__menu__container .plyr__control[role="menuitemradio"].plyr__tab-focus::before,
.plyr__menu__container .plyr__control[role="menuitemradio"]:hover::before {
  background: rgba(35, 40, 47, 0.1);
}

.plyr__menu__container .plyr__menu__value {
  align-items: center;
  display: flex;
  margin-left: auto;
  margin-right: calc((calc(10px * 0.7) - 2) * -1);
  margin-right: calc((calc(var(--plyr-control-spacing, 10px) * 0.7) - 2) * -1);
  overflow: hidden;
  padding-left: calc(calc(10px * 0.7) * 3.5);
  padding-left: calc(calc(var(--plyr-control-spacing, 10px) * 0.7) * 3.5);
  pointer-events: none;
}

.plyr--full-ui input[type="range"] {
  -webkit-appearance: none;
  background: 0 0;
  border: 0;
  border-radius: calc(13px * 2);
  border-radius: calc(var(--plyr-range-thumb-height, 13px) * 2);
  color: #00b3ff;
  color: var(--plyr-range-fill-background,
      var(--plyr-color-main, var(--plyr-color-main, #00b3ff)));
  display: block;
  height: calc((3px * 2) + 13px);
  height: calc((var(--plyr-range-thumb-active-shadow-width, 3px) * 2) + var(--plyr-range-thumb-height, 13px));
  margin: 0;
  padding: 0;
  transition: box-shadow 0.3s ease;
  width: 100%;
}

.plyr--full-ui input[type="range"]::-webkit-slider-runnable-track {
  background: 0 0;
  border: 0;
  border-radius: calc(5px / 2);
  border-radius: calc(var(--plyr-range-track-height, 5px) / 2);
  height: 5px;
  height: var(--plyr-range-track-height, 5px);
  -webkit-transition: box-shadow 0.3s ease;
  transition: box-shadow 0.3s ease;
  -webkit-user-select: none;
  user-select: none;
  background-image: linear-gradient(to right, currentColor 0, transparent 0);
  background-image: linear-gradient(to right,
      currentColor var(--value, 0),
      transparent var(--value, 0));
}

.plyr--full-ui input[type="range"]::-webkit-slider-thumb {
  background: #fff;
  background: var(--plyr-range-thumb-background, #fff);
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(35, 40, 47, 0.15), 0 0 0 1px rgba(35, 40, 47, 0.2);
  box-shadow: var(--plyr-range-thumb-shadow,
      0 1px 1px rgba(35, 40, 47, 0.15),
      0 0 0 1px rgba(35, 40, 47, 0.2));
  height: 13px;
  height: var(--plyr-range-thumb-height, 13px);
  position: relative;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  width: 13px;
  width: var(--plyr-range-thumb-height, 13px);
  -webkit-appearance: none;
  margin-top: calc(((13px - 5px) / 2) * -1);
  margin-top: calc(((var(--plyr-range-thumb-height, 13px) - var(--plyr-range-track-height, 5px)) / 2) * -1);
}

.plyr--full-ui input[type="range"]::-moz-range-track {
  background: 0 0;
  border: 0;
  border-radius: calc(5px / 2);
  border-radius: calc(var(--plyr-range-track-height, 5px) / 2);
  height: 5px;
  height: var(--plyr-range-track-height, 5px);
  -moz-transition: box-shadow 0.3s ease;
  transition: box-shadow 0.3s ease;
  user-select: none;
}

.plyr--full-ui input[type="range"]::-moz-range-thumb {
  background: #fff;
  background: var(--plyr-range-thumb-background, #fff);
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(35, 40, 47, 0.15), 0 0 0 1px rgba(35, 40, 47, 0.2);
  box-shadow: var(--plyr-range-thumb-shadow,
      0 1px 1px rgba(35, 40, 47, 0.15),
      0 0 0 1px rgba(35, 40, 47, 0.2));
  height: 13px;
  height: var(--plyr-range-thumb-height, 13px);
  position: relative;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
  width: 13px;
  width: var(--plyr-range-thumb-height, 13px);
}

.plyr--full-ui input[type="range"]::-moz-range-progress {
  background: currentColor;
  border-radius: calc(5px / 2);
  border-radius: calc(var(--plyr-range-track-height, 5px) / 2);
  height: 5px;
  height: var(--plyr-range-track-height, 5px);
}

.plyr--full-ui input[type="range"]::-ms-track {
  background: 0 0;
  border: 0;
  border-radius: calc(5px / 2);
  border-radius: calc(var(--plyr-range-track-height, 5px) / 2);
  height: 5px;
  height: var(--plyr-range-track-height, 5px);
  -ms-transition: box-shadow 0.3s ease;
  transition: box-shadow 0.3s ease;
  -ms-user-select: none;
  user-select: none;
  color: transparent;
}

.plyr--full-ui input[type="range"]::-ms-fill-upper {
  background: 0 0;
  border: 0;
  border-radius: calc(5px / 2);
  border-radius: calc(var(--plyr-range-track-height, 5px) / 2);
  height: 5px;
  height: var(--plyr-range-track-height, 5px);
  -ms-transition: box-shadow 0.3s ease;
  transition: box-shadow 0.3s ease;
  -ms-user-select: none;
  user-select: none;
}

.plyr--full-ui input[type="range"]::-ms-fill-lower {
  background: 0 0;
  border: 0;
  border-radius: calc(5px / 2);
  border-radius: calc(var(--plyr-range-track-height, 5px) / 2);
  height: 5px;
  height: var(--plyr-range-track-height, 5px);
  -ms-transition: box-shadow 0.3s ease;
  transition: box-shadow 0.3s ease;
  -ms-user-select: none;
  user-select: none;
  background: currentColor;
}

.plyr--full-ui input[type="range"]::-ms-thumb {
  background: #fff;
  background: var(--plyr-range-thumb-background, #fff);
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(35, 40, 47, 0.15), 0 0 0 1px rgba(35, 40, 47, 0.2);
  box-shadow: var(--plyr-range-thumb-shadow,
      0 1px 1px rgba(35, 40, 47, 0.15),
      0 0 0 1px rgba(35, 40, 47, 0.2));
  height: 13px;
  height: var(--plyr-range-thumb-height, 13px);
  position: relative;
  -ms-transition: all 0.2s ease;
  transition: all 0.2s ease;
  width: 13px;
  width: var(--plyr-range-thumb-height, 13px);
  margin-top: 0;
}

.plyr--full-ui input[type="range"]::-ms-tooltip {
  display: none;
}

.plyr--full-ui input[type="range"]:focus {
  outline: 0;
}

.plyr--full-ui input[type="range"]::-moz-focus-outer {
  border: 0;
}

.plyr--full-ui input[type="range"].plyr__tab-focus::-webkit-slider-runnable-track {
  outline-color: #00b3ff;
  outline-color: var(--plyr-tab-focus-color,
      var(--plyr-color-main, var(--plyr-color-main, #00b3ff)));
  outline-offset: 2px;
  outline-style: dotted;
  outline-width: 3px;
}

.plyr--full-ui input[type="range"].plyr__tab-focus::-moz-range-track {
  outline-color: #00b3ff;
  outline-color: var(--plyr-tab-focus-color,
      var(--plyr-color-main, var(--plyr-color-main, #00b3ff)));
  outline-offset: 2px;
  outline-style: dotted;
  outline-width: 3px;
}

.plyr--full-ui input[type="range"].plyr__tab-focus::-ms-track {
  outline-color: #00b3ff;
  outline-color: var(--plyr-tab-focus-color,
      var(--plyr-color-main, var(--plyr-color-main, #00b3ff)));
  outline-offset: 2px;
  outline-style: dotted;
  outline-width: 3px;
}

.plyr__poster {
  background-color: #000;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  transition: opacity 0.2s ease;
  width: 100%;
  z-index: 1;
}

.plyr--stopped.plyr__poster-enabled .plyr__poster {
  opacity: 1;
}

.plyr__time {
  font-size: 13px;
  font-size: var(--plyr-font-size-time, var(--plyr-font-size-small, 13px));
}

.plyr__time+.plyr__time::before {
  content: "\2044";
  margin-right: 10px;
  margin-right: var(--plyr-control-spacing, 10px);
}

@media (max-width: calc(768px - 1)) {
  .plyr__time+.plyr__time {
    display: none;
  }
}

.plyr__tooltip {
  background: rgba(255, 255, 255, 0.9);
  background: var(--plyr-tooltip-background, rgba(255, 255, 255, 0.9));
  border-radius: 3px;
  border-radius: var(--plyr-tooltip-radius, 3px);
  bottom: 100%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  box-shadow: var(--plyr-tooltip-shadow, 0 1px 2px rgba(0, 0, 0, 0.15));
  color: #4a5464;
  color: var(--plyr-tooltip-color, #4a5464);
  font-size: 13px;
  font-size: var(--plyr-font-size-small, 13px);
  font-weight: 400;
  font-weight: var(--plyr-font-weight-regular, 400);
  left: 50%;
  line-height: 1.3;
  margin-bottom: calc(calc(10px / 2) * 2);
  margin-bottom: calc(calc(var(--plyr-control-spacing, 10px) / 2) * 2);
  opacity: 0;
  padding: calc(10px / 2) calc(calc(10px / 2) * 1.5);
  padding: calc(var(--plyr-control-spacing, 10px) / 2) calc(calc(var(--plyr-control-spacing, 10px) / 2) * 1.5);
  pointer-events: none;
  position: absolute;
  transform: translate(-50%, 10px) scale(0.8);
  transform-origin: 50% 100%;
  transition: transform 0.2s 0.1s ease, opacity 0.2s 0.1s ease;
  white-space: nowrap;
  z-index: 2;
}

.plyr__tooltip::before {
  border-left: 4px solid transparent;
  border-left: var(--plyr-tooltip-arrow-size, 4px) solid transparent;
  border-right: 4px solid transparent;
  border-right: var(--plyr-tooltip-arrow-size, 4px) solid transparent;
  border-top: 4px solid rgba(255, 255, 255, 0.9);
  border-top: var(--plyr-tooltip-arrow-size, 4px) solid var(--plyr-tooltip-background, rgba(255, 255, 255, 0.9));
  bottom: calc(4px * -1);
  bottom: calc(var(--plyr-tooltip-arrow-size, 4px) * -1);
  content: "";
  height: 0;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  width: 0;
  z-index: 2;
}

.plyr .plyr__control.plyr__tab-focus .plyr__tooltip,
.plyr .plyr__control:hover .plyr__tooltip,
.plyr__tooltip--visible {
  opacity: 1;
  transform: translate(-50%, 0) scale(1);
}

.plyr .plyr__control:hover .plyr__tooltip {
  z-index: 3;
}

.plyr__controls>.plyr__control:first-child .plyr__tooltip,
.plyr__controls>.plyr__control:first-child+.plyr__control .plyr__tooltip {
  left: 0;
  transform: translate(0, 10px) scale(0.8);
  transform-origin: 0 100%;
}

.plyr__controls>.plyr__control:first-child .plyr__tooltip::before,
.plyr__controls>.plyr__control:first-child+.plyr__control .plyr__tooltip::before {
  left: calc((18px / 2) + calc(10px * 0.7));
  left: calc((var(--plyr-control-icon-size, 18px) / 2) + calc(var(--plyr-control-spacing, 10px) * 0.7));
}

.plyr__controls>.plyr__control:last-child .plyr__tooltip {
  left: auto;
  right: 0;
  transform: translate(0, 10px) scale(0.8);
  transform-origin: 100% 100%;
}

.plyr__controls>.plyr__control:last-child .plyr__tooltip::before {
  left: auto;
  right: calc((18px / 2) + calc(10px * 0.7));
  right: calc((var(--plyr-control-icon-size, 18px) / 2) + calc(var(--plyr-control-spacing, 10px) * 0.7));
  transform: translateX(50%);
}

.plyr__controls>.plyr__control:first-child .plyr__tooltip--visible,
.plyr__controls>.plyr__control:first-child+.plyr__control .plyr__tooltip--visible,
.plyr__controls>.plyr__control:first-child+.plyr__control.plyr__tab-focus .plyr__tooltip,
.plyr__controls>.plyr__control:first-child+.plyr__control:hover .plyr__tooltip,
.plyr__controls>.plyr__control:first-child.plyr__tab-focus .plyr__tooltip,
.plyr__controls>.plyr__control:first-child:hover .plyr__tooltip,
.plyr__controls>.plyr__control:last-child .plyr__tooltip--visible,
.plyr__controls>.plyr__control:last-child.plyr__tab-focus .plyr__tooltip,
.plyr__controls>.plyr__control:last-child:hover .plyr__tooltip {
  transform: translate(0, 0) scale(1);
}

.plyr__progress {
  left: calc(13px * 0.5);
  left: calc(var(--plyr-range-thumb-height, 13px) * 0.5);
  margin-right: 13px;
  margin-right: var(--plyr-range-thumb-height, 13px);
  position: relative;
}

.plyr__progress input[type="range"],
.plyr__progress__buffer {
  margin-left: calc(13px * -0.5);
  margin-left: calc(var(--plyr-range-thumb-height, 13px) * -0.5);
  margin-right: calc(13px * -0.5);
  margin-right: calc(var(--plyr-range-thumb-height, 13px) * -0.5);
  width: calc(100% + 13px);
  width: calc(100% + var(--plyr-range-thumb-height, 13px));
}

.plyr__progress input[type="range"] {
  position: relative;
  z-index: 2;
}

.plyr__progress .plyr__tooltip {
  font-size: 13px;
  font-size: var(--plyr-font-size-time, var(--plyr-font-size-small, 13px));
  left: 0;
}

.plyr__progress__buffer {
  -webkit-appearance: none;
  background: 0 0;
  border: 0;
  border-radius: 100px;
  height: 5px;
  height: var(--plyr-range-track-height, 5px);
  left: 0;
  margin-top: calc((5px / 2) * -1);
  margin-top: calc((var(--plyr-range-track-height, 5px) / 2) * -1);
  padding: 0;
  position: absolute;
  top: 50%;
}

.plyr__progress__buffer::-webkit-progress-bar {
  background: 0 0;
}

.plyr__progress__buffer::-webkit-progress-value {
  background: currentColor;
  border-radius: 100px;
  min-width: 5px;
  min-width: var(--plyr-range-track-height, 5px);
  -webkit-transition: width 0.2s ease;
  transition: width 0.2s ease;
}

.plyr__progress__buffer::-moz-progress-bar {
  background: currentColor;
  border-radius: 100px;
  min-width: 5px;
  min-width: var(--plyr-range-track-height, 5px);
  -moz-transition: width 0.2s ease;
  transition: width 0.2s ease;
}

.plyr__progress__buffer::-ms-fill {
  border-radius: 100px;
  -ms-transition: width 0.2s ease;
  transition: width 0.2s ease;
}

.plyr--loading .plyr__progress__buffer {
  animation: plyr-progress 1s linear infinite;
  background-image: linear-gradient(-45deg,
      rgba(35, 40, 47, 0.6) 25%,
      transparent 25%,
      transparent 50%,
      rgba(35, 40, 47, 0.6) 50%,
      rgba(35, 40, 47, 0.6) 75%,
      transparent 75%,
      transparent);
  background-image: linear-gradient(-45deg,
      var(--plyr-progress-loading-background, rgba(35, 40, 47, 0.6)) 25%,
      transparent 25%,
      transparent 50%,
      var(--plyr-progress-loading-background, rgba(35, 40, 47, 0.6)) 50%,
      var(--plyr-progress-loading-background, rgba(35, 40, 47, 0.6)) 75%,
      transparent 75%,
      transparent);
  background-repeat: repeat-x;
  background-size: 25px 25px;
  background-size: var(--plyr-progress-loading-size, 25px) var(--plyr-progress-loading-size, 25px);
  color: transparent;
}

.plyr--video.plyr--loading .plyr__progress__buffer {
  background-color: rgba(255, 255, 255, 0.25);
  background-color: var(--plyr-video-progress-buffered-background,
      rgba(255, 255, 255, 0.25));
}

.plyr--audio.plyr--loading .plyr__progress__buffer {
  background-color: rgba(193, 200, 209, 0.6);
  background-color: var(--plyr-audio-progress-buffered-background,
      rgba(193, 200, 209, 0.6));
}

.plyr__volume {
  align-items: center;
  display: flex;
  max-width: 110px;
  min-width: 80px;
  position: relative;
  width: 20%;
}

.plyr__volume input[type="range"] {
  margin-left: calc(10px / 2);
  margin-left: calc(var(--plyr-control-spacing, 10px) / 2);
  margin-right: calc(10px / 2);
  margin-right: calc(var(--plyr-control-spacing, 10px) / 2);
  position: relative;
  z-index: 2;
}

.plyr--is-ios .plyr__volume {
  min-width: 0;
  width: auto;
}

.plyr--audio {
  display: block;
}

.plyr--audio .plyr__controls {
  background: #fff;
  background: var(--plyr-audio-controls-background, #fff);
  border-radius: inherit;
  color: #4a5464;
  color: var(--plyr-audio-control-color, #4a5464);
  padding: 10px;
  padding: var(--plyr-control-spacing, 10px);
}

.plyr--audio .plyr__control.plyr__tab-focus,
.plyr--audio .plyr__control:hover,
.plyr--audio .plyr__control[aria-expanded="true"] {
  background: #00b3ff;
  background: var(--plyr-audio-control-background-hover,
      var(--plyr-color-main, var(--plyr-color-main, #00b3ff)));
  color: #fff;
  color: var(--plyr-audio-control-color-hover, #fff);
}

.plyr--full-ui.plyr--audio input[type="range"]::-webkit-slider-runnable-track {
  background-color: rgba(193, 200, 209, 0.6);
  background-color: var(--plyr-audio-range-track-background,
      var(--plyr-audio-progress-buffered-background, rgba(193, 200, 209, 0.6)));
}

.plyr--full-ui.plyr--audio input[type="range"]::-moz-range-track {
  background-color: rgba(193, 200, 209, 0.6);
  background-color: var(--plyr-audio-range-track-background,
      var(--plyr-audio-progress-buffered-background, rgba(193, 200, 209, 0.6)));
}

.plyr--full-ui.plyr--audio input[type="range"]::-ms-track {
  background-color: rgba(193, 200, 209, 0.6);
  background-color: var(--plyr-audio-range-track-background,
      var(--plyr-audio-progress-buffered-background, rgba(193, 200, 209, 0.6)));
}

.plyr--full-ui.plyr--audio input[type="range"]:active::-webkit-slider-thumb {
  box-shadow: 0 1px 1px rgba(35, 40, 47, 0.15), 0 0 0 1px rgba(35, 40, 47, 0.2),
    0 0 0 3px rgba(35, 40, 47, 0.1);
  box-shadow: var(--plyr-range-thumb-shadow,
      0 1px 1px rgba(35, 40, 47, 0.15),
      0 0 0 1px rgba(35, 40, 47, 0.2)),
    0 0 0 var(--plyr-range-thumb-active-shadow-width, 3px) var(--plyr-audio-range-thumb-active-shadow-color, rgba(35, 40, 47, 0.1));
}

.plyr--full-ui.plyr--audio input[type="range"]:active::-moz-range-thumb {
  box-shadow: 0 1px 1px rgba(35, 40, 47, 0.15), 0 0 0 1px rgba(35, 40, 47, 0.2),
    0 0 0 3px rgba(35, 40, 47, 0.1);
  box-shadow: var(--plyr-range-thumb-shadow,
      0 1px 1px rgba(35, 40, 47, 0.15),
      0 0 0 1px rgba(35, 40, 47, 0.2)),
    0 0 0 var(--plyr-range-thumb-active-shadow-width, 3px) var(--plyr-audio-range-thumb-active-shadow-color, rgba(35, 40, 47, 0.1));
}

.plyr--full-ui.plyr--audio input[type="range"]:active::-ms-thumb {
  box-shadow: 0 1px 1px rgba(35, 40, 47, 0.15), 0 0 0 1px rgba(35, 40, 47, 0.2),
    0 0 0 3px rgba(35, 40, 47, 0.1);
  box-shadow: var(--plyr-range-thumb-shadow,
      0 1px 1px rgba(35, 40, 47, 0.15),
      0 0 0 1px rgba(35, 40, 47, 0.2)),
    0 0 0 var(--plyr-range-thumb-active-shadow-width, 3px) var(--plyr-audio-range-thumb-active-shadow-color, rgba(35, 40, 47, 0.1));
}

.plyr--audio .plyr__progress__buffer {
  color: rgba(193, 200, 209, 0.6);
  color: var(--plyr-audio-progress-buffered-background,
      rgba(193, 200, 209, 0.6));
}

.plyr--video {
  background: #000;
  overflow: hidden;
}

.plyr--video.plyr--menu-open {
  overflow: visible;
}

.plyr__video-wrapper {
  background: #000;
  height: 100%;
  margin: auto;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.plyr__video-embed,
.plyr__video-wrapper--fixed-ratio {
  height: 0;
  padding-bottom: 56.25%;
}

.plyr__video-embed iframe,
.plyr__video-wrapper--fixed-ratio video {
  border: 0;
  left: 0;
  position: absolute;
  top: 0;
}

.plyr--full-ui .plyr__video-embed>.plyr__video-embed__container {
  padding-bottom: 190%;
  position: relative;
  transform: translateY(-38.28125%);
}

.plyr--video .plyr__controls {
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75));
  background: var(--plyr-video-controls-background,
      linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75)));
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  bottom: 0;
  color: #fff;
  color: var(--plyr-video-control-color, #fff);
  left: 0;
  padding: calc(10px / 2);
  padding: calc(var(--plyr-control-spacing, 10px) / 2);
  padding-top: calc(10px * 2);
  padding-top: calc(var(--plyr-control-spacing, 10px) * 2);
  position: absolute;
  right: 0;
  transition: opacity 0.4s ease-in-out, transform 0.4s ease-in-out;
  z-index: 3;
}

@media (min-width: 480px) {
  .plyr--video .plyr__controls {
    padding: 10px;
    padding: var(--plyr-control-spacing, 10px);
    padding-top: calc(10px * 3.5);
    padding-top: calc(var(--plyr-control-spacing, 10px) * 3.5);
  }
}

.plyr--video.plyr--hide-controls .plyr__controls {
  opacity: 0;
  pointer-events: none;
  transform: translateY(100%);
}

.plyr--video .plyr__control.plyr__tab-focus,
.plyr--video .plyr__control:hover,
.plyr--video .plyr__control[aria-expanded="true"] {
  background: #00b3ff;
  background: var(--plyr-video-control-background-hover,
      var(--plyr-color-main, var(--plyr-color-main, #00b3ff)));
  color: #fff;
  color: var(--plyr-video-control-color-hover, #fff);
}

.plyr__control--overlaid {
  background: #00b3ff;
  background: var(--plyr-video-control-background-hover,
      var(--plyr-color-main, var(--plyr-color-main, #00b3ff)));
  border: 0;
  border-radius: 100%;
  color: #fff;
  color: var(--plyr-video-control-color, #fff);
  display: none;
  left: 50%;
  opacity: 0.9;
  padding: calc(10px * 1.5);
  padding: calc(var(--plyr-control-spacing, 10px) * 1.5);
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: 0.3s;
  z-index: 2;
}

.plyr__control--overlaid svg {
  left: 2px;
  position: relative;
}

.plyr__control--overlaid:focus,
.plyr__control--overlaid:hover {
  opacity: 1;
}

.plyr--playing .plyr__control--overlaid {
  opacity: 0;
  visibility: hidden;
}

.plyr--full-ui.plyr--video .plyr__control--overlaid {
  display: block;
}

.plyr--full-ui.plyr--video input[type="range"]::-webkit-slider-runnable-track {
  background-color: rgba(255, 255, 255, 0.25);
  background-color: var(--plyr-video-range-track-background,
      var(--plyr-video-progress-buffered-background, rgba(255, 255, 255, 0.25)));
}

.plyr--full-ui.plyr--video input[type="range"]::-moz-range-track {
  background-color: rgba(255, 255, 255, 0.25);
  background-color: var(--plyr-video-range-track-background,
      var(--plyr-video-progress-buffered-background, rgba(255, 255, 255, 0.25)));
}

.plyr--full-ui.plyr--video input[type="range"]::-ms-track {
  background-color: rgba(255, 255, 255, 0.25);
  background-color: var(--plyr-video-range-track-background,
      var(--plyr-video-progress-buffered-background, rgba(255, 255, 255, 0.25)));
}

.plyr--full-ui.plyr--video input[type="range"]:active::-webkit-slider-thumb {
  box-shadow: 0 1px 1px rgba(35, 40, 47, 0.15), 0 0 0 1px rgba(35, 40, 47, 0.2),
    0 0 0 3px rgba(255, 255, 255, 0.5);
  box-shadow: var(--plyr-range-thumb-shadow,
      0 1px 1px rgba(35, 40, 47, 0.15),
      0 0 0 1px rgba(35, 40, 47, 0.2)),
    0 0 0 var(--plyr-range-thumb-active-shadow-width, 3px) var(--plyr-audio-range-thumb-active-shadow-color,
      rgba(255, 255, 255, 0.5));
}

.plyr--full-ui.plyr--video input[type="range"]:active::-moz-range-thumb {
  box-shadow: 0 1px 1px rgba(35, 40, 47, 0.15), 0 0 0 1px rgba(35, 40, 47, 0.2),
    0 0 0 3px rgba(255, 255, 255, 0.5);
  box-shadow: var(--plyr-range-thumb-shadow,
      0 1px 1px rgba(35, 40, 47, 0.15),
      0 0 0 1px rgba(35, 40, 47, 0.2)),
    0 0 0 var(--plyr-range-thumb-active-shadow-width, 3px) var(--plyr-audio-range-thumb-active-shadow-color,
      rgba(255, 255, 255, 0.5));
}

.plyr--full-ui.plyr--video input[type="range"]:active::-ms-thumb {
  box-shadow: 0 1px 1px rgba(35, 40, 47, 0.15), 0 0 0 1px rgba(35, 40, 47, 0.2),
    0 0 0 3px rgba(255, 255, 255, 0.5);
  box-shadow: var(--plyr-range-thumb-shadow,
      0 1px 1px rgba(35, 40, 47, 0.15),
      0 0 0 1px rgba(35, 40, 47, 0.2)),
    0 0 0 var(--plyr-range-thumb-active-shadow-width, 3px) var(--plyr-audio-range-thumb-active-shadow-color,
      rgba(255, 255, 255, 0.5));
}

.plyr--video .plyr__progress__buffer {
  color: rgba(255, 255, 255, 0.25);
  color: var(--plyr-video-progress-buffered-background,
      rgba(255, 255, 255, 0.25));
}

.plyr:-webkit-full-screen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%;
}

.plyr:-ms-fullscreen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%;
}

.plyr:fullscreen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%;
}

.plyr:-webkit-full-screen video {
  height: 100%;
}

.plyr:-ms-fullscreen video {
  height: 100%;
}

.plyr:fullscreen video {
  height: 100%;
}

.plyr:-webkit-full-screen .plyr__video-wrapper {
  height: 100%;
  position: static;
}

.plyr:-ms-fullscreen .plyr__video-wrapper {
  height: 100%;
  position: static;
}

.plyr:fullscreen .plyr__video-wrapper {
  height: 100%;
  position: static;
}

.plyr:-webkit-full-screen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  position: relative;
}

.plyr:-ms-fullscreen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  position: relative;
}

.plyr:fullscreen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  position: relative;
}

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen {
  display: block;
}

.plyr:-ms-fullscreen .plyr__control .icon--exit-fullscreen {
  display: block;
}

.plyr:fullscreen .plyr__control .icon--exit-fullscreen {
  display: block;
}

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen+svg {
  display: none;
}

.plyr:-ms-fullscreen .plyr__control .icon--exit-fullscreen+svg {
  display: none;
}

.plyr:fullscreen .plyr__control .icon--exit-fullscreen+svg {
  display: none;
}

.plyr:-webkit-full-screen.plyr--hide-controls {
  cursor: none;
}

.plyr:-ms-fullscreen.plyr--hide-controls {
  cursor: none;
}

.plyr:fullscreen.plyr--hide-controls {
  cursor: none;
}

@media (min-width: 1024px) {
  .plyr:-webkit-full-screen .plyr__captions {
    font-size: 21px;
    font-size: var(--plyr-font-size-xlarge, 21px);
  }

  .plyr:-ms-fullscreen .plyr__captions {
    font-size: 21px;
    font-size: var(--plyr-font-size-xlarge, 21px);
  }

  .plyr:fullscreen .plyr__captions {
    font-size: 21px;
    font-size: var(--plyr-font-size-xlarge, 21px);
  }
}

.plyr:-webkit-full-screen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%;
}

.plyr:-webkit-full-screen video {
  height: 100%;
}

.plyr:-webkit-full-screen .plyr__video-wrapper {
  height: 100%;
  position: static;
}

.plyr:-webkit-full-screen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  position: relative;
}

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen {
  display: block;
}

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen+svg {
  display: none;
}

.plyr:-webkit-full-screen.plyr--hide-controls {
  cursor: none;
}

@media (min-width: 1024px) {
  .plyr:-webkit-full-screen .plyr__captions {
    font-size: 21px;
    font-size: var(--plyr-font-size-xlarge, 21px);
  }
}

.plyr:-moz-full-screen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%;
}

.plyr:-moz-full-screen video {
  height: 100%;
}

.plyr:-moz-full-screen .plyr__video-wrapper {
  height: 100%;
  position: static;
}

.plyr:-moz-full-screen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  position: relative;
}

.plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen {
  display: block;
}

.plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen+svg {
  display: none;
}

.plyr:-moz-full-screen.plyr--hide-controls {
  cursor: none;
}

@media (min-width: 1024px) {
  .plyr:-moz-full-screen .plyr__captions {
    font-size: 21px;
    font-size: var(--plyr-font-size-xlarge, 21px);
  }
}

.plyr:-ms-fullscreen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%;
}

.plyr:-ms-fullscreen video {
  height: 100%;
}

.plyr:-ms-fullscreen .plyr__video-wrapper {
  height: 100%;
  position: static;
}

.plyr:-ms-fullscreen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  position: relative;
}

.plyr:-ms-fullscreen .plyr__control .icon--exit-fullscreen {
  display: block;
}

.plyr:-ms-fullscreen .plyr__control .icon--exit-fullscreen+svg {
  display: none;
}

.plyr:-ms-fullscreen.plyr--hide-controls {
  cursor: none;
}

@media (min-width: 1024px) {
  .plyr:-ms-fullscreen .plyr__captions {
    font-size: 21px;
    font-size: var(--plyr-font-size-xlarge, 21px);
  }
}

.plyr--fullscreen-fallback {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%;
  bottom: 0;
  display: block;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10000000;
}

.plyr--fullscreen-fallback video {
  height: 100%;
}

.plyr--fullscreen-fallback .plyr__video-wrapper {
  height: 100%;
  position: static;
}

.plyr--fullscreen-fallback.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  position: relative;
}

.plyr--fullscreen-fallback .plyr__control .icon--exit-fullscreen {
  display: block;
}

.plyr--fullscreen-fallback .plyr__control .icon--exit-fullscreen+svg {
  display: none;
}

.plyr--fullscreen-fallback.plyr--hide-controls {
  cursor: none;
}

@media (min-width: 1024px) {
  .plyr--fullscreen-fallback .plyr__captions {
    font-size: 21px;
    font-size: var(--plyr-font-size-xlarge, 21px);
  }
}

.plyr__ads {
  border-radius: inherit;
  bottom: 0;
  cursor: pointer;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}

.plyr__ads>div,
.plyr__ads>div iframe {
  height: 100%;
  position: absolute;
  width: 100%;
}

.plyr__ads::after {
  background: #23282f;
  border-radius: 2px;
  bottom: 10px;
  bottom: var(--plyr-control-spacing, 10px);
  color: #fff;
  content: attr(data-badge-text);
  font-size: 11px;
  padding: 2px 6px;
  pointer-events: none;
  position: absolute;
  right: 10px;
  right: var(--plyr-control-spacing, 10px);
  z-index: 3;
}

.plyr__ads::after:empty {
  display: none;
}

.plyr__cues {
  background: currentColor;
  display: block;
  height: 5px;
  height: var(--plyr-range-track-height, 5px);
  left: 0;
  margin: -var(--plyr-range-track-height, 5px) / 2 0 0;
  opacity: 0.8;
  position: absolute;
  top: 50%;
  width: 3px;
  z-index: 3;
}

.plyr__preview-thumb {
  background-color: rgba(255, 255, 255, 0.9);
  background-color: var(--plyr-tooltip-background, rgba(255, 255, 255, 0.9));
  border-radius: 3px;
  bottom: 100%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  box-shadow: var(--plyr-tooltip-shadow, 0 1px 2px rgba(0, 0, 0, 0.15));
  margin-bottom: calc(calc(10px / 2) * 2);
  margin-bottom: calc(calc(var(--plyr-control-spacing, 10px) / 2) * 2);
  opacity: 0;
  padding: 3px;
  padding: var(--plyr-tooltip-radius, 3px);
  pointer-events: none;
  position: absolute;
  transform: translate(0, 10px) scale(0.8);
  transform-origin: 50% 100%;
  transition: transform 0.2s 0.1s ease, opacity 0.2s 0.1s ease;
  z-index: 2;
}

.plyr__preview-thumb--is-shown {
  opacity: 1;
  transform: translate(0, 0) scale(1);
}

.plyr__preview-thumb::before {
  border-left: 4px solid transparent;
  border-left: var(--plyr-tooltip-arrow-size, 4px) solid transparent;
  border-right: 4px solid transparent;
  border-right: var(--plyr-tooltip-arrow-size, 4px) solid transparent;
  border-top: 4px solid rgba(255, 255, 255, 0.9);
  border-top: var(--plyr-tooltip-arrow-size, 4px) solid var(--plyr-tooltip-background, rgba(255, 255, 255, 0.9));
  bottom: calc(4px * -1);
  bottom: calc(var(--plyr-tooltip-arrow-size, 4px) * -1);
  content: "";
  height: 0;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  width: 0;
  z-index: 2;
}

.plyr__preview-thumb__image-container {
  background: #c1c8d1;
  border-radius: calc(3px - 1px);
  border-radius: calc(var(--plyr-tooltip-radius, 3px) - 1px);
  overflow: hidden;
  position: relative;
  z-index: 0;
}

.plyr__preview-thumb__image-container img {
  height: 100%;
  left: 0;
  max-height: none;
  max-width: none;
  position: absolute;
  top: 0;
  width: 100%;
}

.plyr__preview-thumb__time-container {
  bottom: 6px;
  left: 0;
  position: absolute;
  right: 0;
  white-space: nowrap;
  z-index: 3;
}

.plyr__preview-thumb__time-container span {
  background-color: rgba(0, 0, 0, 0.55);
  border-radius: calc(3px - 1px);
  border-radius: calc(var(--plyr-tooltip-radius, 3px) - 1px);
  color: #fff;
  font-size: 13px;
  font-size: var(--plyr-font-size-time, var(--plyr-font-size-small, 13px));
  padding: 3px 6px;
}

.plyr__preview-scrubbing {
  bottom: 0;
  filter: blur(1px);
  height: 100%;
  left: 0;
  margin: auto;
  opacity: 0;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity 0.3s ease;
  width: 100%;
  z-index: 1;
}

.plyr__preview-scrubbing--is-shown {
  opacity: 1;
}

.plyr__preview-scrubbing img {
  height: 100%;
  left: 0;
  max-height: none;
  max-width: none;
  object-fit: contain;
  position: absolute;
  top: 0;
  width: 100%;
}

.plyr--no-transition {
  transition: none !important;
}

.plyr__sr-only {
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  border: 0 !important;
  height: 1px !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
}

.plyr [hidden] {
  display: none !important;
}

/* .active {
  color: #1cb7f2 !important;
} */
.dropdown-item:hover,
.dropdown-item:focus {
  color: #1cb7f2;
  text-decoration: none;
  background-color: rgba(28, 183, 242, 0.16);
}

button.close {
  outline: none;
}

img {
  outline: none;
}

.waXoR .temp_video {
  height: 229px !important;
}

.pt-44 {
  padding-top: 24px;
}

.pb-43 {
  padding-bottom: 23px;
}

All Template .retry-failed,
.retry-icon {
  color: #d75348 !important;
}

.sm-text,
.text-white {
  color: #fff !important;
}

#project-section .status.rendering p,
#project-section .status.transcribing p {
  flex-basis: 100%;
  font-weight: 500;

  align-self: flex-start;
}

.custom-ico-bg {
  border: none;
  color: rgb(255, 255, 255) !important;
  background: rgb(21, 10, 10, 0.6) !important;
  padding: 12px;
  border-radius: 50%;
}

.action-icon {
  outline: none !important;
}

.pt-0 {
  padding-top: 0px !important;
}

/* .active {
  color: #1cb7f2 !important;
  font-weight: 800;
} */

.pink-btn {
  background: linear-gradient(to right, #dd1b42 0%, #dd1b42 50%, #f04c6e 100%);
  background-color: #f04c6e !important;
  width: 100%;
  padding: 7px;
  transition: all 1s ease;
  color: white !important;
  cursor: pointer;
  z-index: 9;
}

.pink-btn:hover {
  background: #f04c6e;
  transition: all 1s ease-in-out;
  background-position: right center !important;
  background: -moz-linear-gradient(left, #f04c6e 0%, #f04c6e 100%);
  background: -webkit-linear-gradient(left, #f04c6e 0%, #dd1b42 100%);
  background: linear-gradient(to right, #f04c6e 0%, #dd1b42 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#2989d8', endColorstr='#2989d8', GradientType=1);
  color: #fff;
  transition: all 1s ease;
  color: white;
}

.btn-blue {
  background: #5458e0;
  background: linear-gradient(to right, #5458e0 0%, #444aec 50%, #6567b6 100%);
  background: -moz-linear-gradient(left, #5458e0 0%, #444aec 50%, #6567b6 100%);
  background: -webkit-linear-gradient(left,
      #5458e0 0%,
      #444aec 50%,
      #6567b6 100%);
  color: white !important;
  width: 200px;
  padding: 7px !important;
  border-radius: 5px;
  text-align: center !important;
  transition: all 1s ease;
  cursor: pointer;
  z-index: 9;
}

.btn-blue:hover {
  background: #5458e0;
  color: white !important;
  background-position: right center !important;
  background: linear-gradient(to right, #6567b6 0%, #444aec 50%, #5458e0 100%);
  background: -moz-linear-gradient(left, #6567b6 0%, #444aec 50%, #5458e0 100%);
  background: -webkit-linear-gradient(left,
      #6567b6 0%,
      #444aec 50%,
      #5458e0 100%);
  font-size: 18px;
  width: 200px;
  padding: 7px !important;
  border-radius: 5px;
  text-align: center !important;
  transition: all 1s ease;
}

.cp {
  cursor: pointer;
}

.video-react .video-react-video {
  width: 96% important;
}

.membership-level-text {
  cursor: text;
}

#navbar-color {
  background-color: #ececec;
}

.navbar-nav {
  height: 84px;
  margin-top: 0px !important;
}

.navbar-nav a .active {
  color: "#ef4949";
}

.next-btn a {
  font-weight: normal;
  padding: 10px 40px;
  background: #ef4949;
  color: #fff !important;
}

.next-btn a:hover {
  background-color: #fff !important;
  color: #ef4949 !important;
}

.transcribe {
  margin-top: 50px;
  overflow: scroll !important;
  height: 192px;
}

.main_transcribe {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.main_transcribe .timing li {
  list-style: none;
}

.main_transcribe .text textarea {
  height: 56px;
}

#bg-app {
  background: #1a1c2d;
  background: linear-gradient(to right, #1a1c2d 0%, #050606 100%);
  background: -moz-linear-gradient(left, #1a1c2d 0%, #050606 100%);
  background: -webkit-linear-gradient(left, #1a1c2d 0%, #050606 100%);
  background: linear-gradient(left, #1a1c2d 0%, #050606 100%);
}

.create-head-sec .container {
  max-width: 1200px !important;
}

.createvideo-btn a:hover {
  background-color: #ffffff !important;
  color: #f35247 !important;
}

.my-custom:hover {
  background-color: #ffffff !important;
  color: #f35247 !important;
}

/* .text-link {
  color: #ff949f !important;
}

.text-link:hover {
  color: #fd9ca6 !important;
}

.text-link:active {
  color: #fd9ca6 !important;
} */
#app-header .dropdown-menu a {
  color: #fff !important;
}

#app-header .navbar-nav li a:hover {
  color: #ff949f !important;
}

#footer-nav li a {
  color: #ffffff !important;
}

#footer-nav li a:hover {
  color: #ff949f !important;
}

.copyright p {
  color: #ffffff !important;
}

.copyright p a {
  color: #ffffff !important;
}

.copyright p:hover {
  color: #ff949f !important;
}

.copyright p:hover a {
  color: #ff949f !important;
}

/* carete podcast Start */

.create-prodcast-sec {
  padding: 148px 0px;
  background: linear-gradient(to right, #1a1c2d 0%, #050606 100%);
  z-index: 0;
}

.create-prodcast-sec .create-head h1 {
  font-size: 46px !important;
  font-weight: 600;
}

.create-tab .nav-tabs .nav-link.active span {
  padding: 0px !important;
  width: 70px;
  height: 70px;
  border-radius: 50px;
  line-height: 70px;
  text-align: center;
  display: block;
  background: #f56262 !important;
  color: #ffffff !important;
  margin-bottom: 10px;
  font-weight: normal !important;
}

.create-tab .nav-tabs .nav-link.active {
  border-radius: 0px;
  background: transparent;
  padding: 0px;
  border: none !important;
  text-align: center;
  color: #f56262 !important;
  z-index: 1;
  font-weight: normal !important;
}

.create-tab .nav-tabs .nav-link:hover {
  border: none !important;
}

.create-tab .nav-tabs .nav-link {
  text-align: center;
  padding: 0px;
  border: none;
  z-index: 1;
  color: #fff !important;
}

.create-tab .nav-tabs .nav-link span {
  padding: 0px !important;
  width: 70px;
  height: 70px;
  border-radius: 50px;
  line-height: 70px;
  text-align: center;
  display: block;
  background: #ffffff !important;
  /* color: #ff949f !important; */
  z-index: 1;
  margin-bottom: 10px;
}

.nav-tabs {
  position: relative;
  border-bottom: none !important;
}

.nav-tabs::before {
  position: absolute;
  content: "";
  border: 1px solid #ffffff;
  width: 97%;
  top: 40%;
  left: 1%;
  z-index: 1;
}

.upload-body {
  background-image: linear-gradient(to right, #1a1c2d 0%, #050606 100%);
  position: relative;
  padding: 50px;
  box-shadow: 0px 0px 5px rgba(255, 255, 255, 0.5);
}

.upload-icon {
  z-index: 2;
  position: relative;
}

.upload-icon span {
  font-size: 40px !important;
}

.upload-icon h6 {
  margin-top: 10px;
  font-size: 14px !important;
  color: #fff !important;
  font-weight: normal;
}

.upload-board {
  position: relative;
  padding: 30px;
}

.upload-board::before {
  content: "";
  position: absolute;
  border: 3px dashed #ffffff;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}

.upload-audio h5 {
  position: absolute !important;
  background-color: rgb(24 29 33) !important;
  left: 43%;
  color: #fff !important;
  margin-top: 11px;
  font-size: 14px !important;
  padding: 0px 10px;
  font-weight: normal;
}

.play-icon span {
  font-size: 40px !important;
}

.play-icon {
  cursor: pointer;
}

.play-icon span:hover {
  color: #f84440;
}

.start-time .form-group {
  background-color: #000;
  padding: 10px;
  border-radius: 5px;
  border: 0.3px solid #747474;
  text-align: center;
}

.start-time .form-group label {
  margin: 0px !important;
  font-size: 12px !important;
}

.start-time .form-group .form-control {
  background-color: transparent;
  border-radius: none !important;
  border: none !important;
  padding: 0px;
  height: 20px !important;
  text-align: center;
  color: #495057;
}

.start-time input:focus {
  box-shadow: none !important;
}

.form-switch .form-check-input {
  width: 45px;
  height: 20px;
  background-color: #f84440;
  border-color: #f84440;
}

.card-body.text-darks {
  background-color: #000 !important;
}

.card-body h6 {
  font-weight: normal !important;
}

.form-switch .form-check-label {
  font-size: 20px !important;
}

.Add-caption {
  padding: 25px;
  border: 1px solid #fff;
}

.form-check .form-check-input.size {
  margin-left: 24px;
}

.customize-box .nav .nav-link {
  padding: 20px 30px;
  color: #747474 !important;
  background-image: linear-gradient(to right, #1a1c2d 0%, #050606 100%);
  margin-bottom: 2px;
  box-shadow: 0px 0px 5px rgba(255, 255, 255, 0.5);
}

.customize-box .nav .nav-link.active {
  background-color: #f06060;
  color: #ffffff !important;
}

.search-bar .input-group .form-control {
  background: #161826;
  border: none;
  color: #ffffff !important;
}

span#basic-addon22 {
  background: #161826 !important;
  border: none !important;
  color: #fff !important;
  border-left: 1px solid #ffffff;
}

.search-bar .input-group-append {
  border: 1px solid #747474;
  margin-left: 0px !important;
}

.search-bar input {
  border: 1px solid #747474 !important;
}

.dimension span {
  font-size: 18px !important;
}

.dimension label {
  font-size: 18px !important;
  margin-top: 20px;
}

.dimension select {
  margin-left: 20px;
  width: 70px;
  margin-top: 20px;
}

.preview-tab {
  width: 100%;
  height: 350px;
  /*background-image: linear-gradient(to right, #1a1c2d 0%, #050606 100%);*/
  display: flex;
  justify-content: center;
}

.preview-9 {
  height: 400px !important;
}

.general-content {
  margin-top: 20px;
}

.wavefrom-box {
  background-color: #ffffff;
  height: 50px;
  width: 100%;
}

.search-bar input:focus {
  box-shadow: none !important;
}

.img-box {
  width: 100%;
  height: 100px;
  background-color: #ffffff;
}

.font-style .form-group {
  margin-left: 0px !important;
}

.start-time .times {
  color: #747474 !important;
}

.start-time .seconds {
  color: #747474 !important;
}

#footer-nav li.nav-item {
  display: inline !important;
}

#project-section .view-all:hover {
  color: #fa6161 !important;
}

#project-section .view-all:hover span {
  color: #fa6161 !important;
}

a#avatar span {
  width: 40px;
  height: 40px;
  display: inline-block;
  background: #fff;
  text-align: center;
  line-height: 40px;
  border-radius: 50px;
}

.audio-cliper {
  height: 48px;
  border: 0.3px solid #747474;
  text-align: center;
  line-height: 48px;
  background-color: #000;
}

#reload {
  animation: rotation 1s infinite linear;
  margin: auto;
  display: block;
  margin-top: 10px;
  width: 25px;
}

.centered {
  /* text-align: center; */
  position: absolute !important;
  /* top: 10rem;
  left: 32%; */
  padding: 2px;
  line-height: 0rem;

  top: 10.4rem;
  left: 34%;
}

.center-left {
  /* text-align: left; */
  position: absolute !important;
  top: 10rem;
  left: 0;
  padding: 2px;
}

.center-right {
  /* text-align: right; */
  position: absolute !important;
  top: 10rem;
  right: 0;
  padding: 2px;
}

.top-left {
  /* text-align: left; */
  position: absolute !important;
  top: 0;
  left: 1%;
  padding: 2px;
}

.top-right {
  /* text-align: left; */
  position: absolute !important;
  top: 0;
  right: 0;
  padding: 2px;
}

.top-center {
  position: absolute !important;
  padding: 2px;
  line-height: 0rem;
  top: 0;
  left: 34%;
}

.bottom-center {
  position: absolute !important;
  padding: 2px;
  line-height: 0rem;
  bottom: 0;
  left: 34%;
}

.bottom-left {
  position: absolute !important;
  padding: 2px;
  line-height: 0rem;
  bottom: 0;
  left: 0;
}

.bottom-right {
  position: absolute !important;
  padding: 2px;
  line-height: 0rem;
  bottom: 0;
  right: 0;
}

.select-media {
  display: flex;
  justify-content: center;
  align-items: center;
}

.select-media input[type="checkbox"],
input[type="radio"] {
  width: 15px;
  height: 15px;
}

.remove label {
  font-size: 15px !important;
}

.image-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.main-wrapper {
  background: linear-gradient(to right, #050606 0%, #1a1c2d 100%);
}

.show-modal {
  display: block !important;
  background: rgba(0, 0, 0, 0.5);
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

.audio-cliper .form-control {
  background-color: transparent !important;
  border: none !important;
  border-radius: none !important;
  height: 100%;
  text-align: center;
}

.audio-cliper input:focus {
  box-shadow: none !important;
}

.border-dark .card-header {
  background-color: #000 !important;
  color: #747474 !important;
  font-weight: normal !important;
  border-bottom: 1px solid;
}

.render-btn.text-end a {
  font-weight: normal;
  padding: 10px 30px;
  background: #d75348;
  color: #fff !important;
}

.render-btn a:hover {
  background-color: #fff;
  color: #d75348 !important;
}

.form-switch .form-check-input:checked {
  background-repeat: no-repeat !important;
}

.tab-content>.active>.row {
  background-image: linear-gradient(to right, #1a1c2d 0%, #050606 100%);
  height: 350px !important;
}

.caption-content span {
  font-size: 18px !important;
}

.caption-content button {
  background-color: #d75348;
  color: #ffffff;
  border-radius: 5px;
}

.caption-content button:hover {
  color: #ffffff;
}

.background h6 {
  font-size: 18px;
  font-weight: normal;
  color: #9e9e9e;
}

.uplaod-sec {
  width: 100%;
  height: 110px;
  border: 1px solid #ffffff;
}

.library-upload .tab-pane {
  min-height: 50vh;
}

.uplaod-sec h6 {
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  margin-top: 5px;
}

.uplaod-sec span {
  font-size: 26px;
  display: flex;
  justify-content: center;
}

.font-style h6 {
  font-size: 18px;
  font-weight: normal;
  color: #999c9e !important;
}

.createvideo-btn a {
  padding: 10px 30px;
  background: #ea5e5f;
  color: #fff;
  text-decoration: none;
}

.my-custom {
  padding: 5px 20px;
  background: #ea5e5f;
  color: #fff;
  text-decoration: none;
  border: none;
  cursor: pointer;
}

.createvideo-btn {
  margin-left: 25px !important;
}

.create-prodcast-head li {
  font-size: 20px;
  color: #fff;
}

.create-head-sec {
  padding: 10px 0px;
  background-color: #1a1c2d;
  margin-top: 78px;
  position: fixed;
  width: 100%;
  z-index: 1;
}

.custom-border {
  border: 1px dashed #fff;
}

.font-size {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.font-size h6 {
  color: #999c9e !important;
  margin-right: 5px;
}

.font-size input {
  width: 70px;
  height: 35px;
  background-color: rgb(255, 255, 255);
  margin-left: 10px;
  border: none;
  float: right;
  position: relative;
  border-radius: 0.25rem;
}




.create-head-input .form-control {
  background: #000 !important;
  border: none;
  border-radius: 5px !important;
  color: #ffffff !important;
}

.create-head-input .input-group {
  border: 1px solid #747474;
  border-radius: 5px !important;
}

.create-head-input input:focus {
  box-shadow: none;
}

.create-head-input .input-group-text {
  background-color: #000 !important;
  border: none !important;
  color: #ffffff !important;
}

header::after {
  display: none;
}

.navbar-nav {
  height: 84px !important;
}

#app-header,
#app-editor-header {
  background-color: #060818;
  padding: 0 !important;
  top: 0;
  left: 0;
  z-index: 11;
  box-shadow: 0 2px 20px 0 #060818;
  max-height: 80px;
}

.videoreel-favicon-icon {
  max-width: 150px !important;
}

#cs-cta .cs-btn:hover {
  box-shadow: none !important;
  color: #f84440 !important;
}

.myvidoe-prodcast-head li {
  font-size: 16px;
  color: #fff;
}

.myvideo-head-sec .createvideo-btn a:hover {
  color: #ea5e5f;
  background-color: #ffffff;
}

.myvideo-head-sec .create-head-input .form-control {
  color: #ffffff !important;
}

.myvidoe-prodcast-head li:nth-child(2) {
  margin-left: 10px;
  border-left: 1px solid #ffffff;
  padding-left: 10px;
}

.myvideo-head-sec {
  padding: 10px 0px;
  background-color: #1a1c2d;
  width: 100%;
  z-index: 9;
  position: fixed;
}

.myvideo-head-sec .container {
  max-width: 1200px;
}

.addBorder {
  border: 2px solid #fff;
}

.wavesurfer-handle {
  background-color: #747474 !important;
}